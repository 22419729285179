import React from 'react';
import { VamosMapContext } from '../context';

/**
 * Custom hook to use functions exported by VamosMapContext
 * @returns useVamosMap hook
 */
export const useVamosMap = () => {
  return React.useContext(VamosMapContext);
};
