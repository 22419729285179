import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

const SiteImageUpload = ({
  addImage,
  getImage,
}) => {
  const [fileName, setFileName] = useState(undefined);

  const handleInputChange = (e) => {
    e.preventDefault();
    getImage(e);

    const filePath = e.target.value;
    const shortPath = filePath.substr(filePath.lastIndexOf('\\') + 1);
    setFileName(shortPath);
  };

  return (
    <>
      <div className="container">
        <div className="input-group mb-5">
          <div className="custom-file">
            <label className="custom-file-label col-form-label text-truncate text-left w-auto" htmlFor="inputGroupFile ">{fileName === undefined ? 'Choose file' : fileName} </label>
            <input
              type="file"
              className="custom-file-input form-control-file"
              id="inputGroupFile"
              accept=".jpeg, .png, .jpg"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
      </div>
      <Modal.Footer className="justify-content-center modal-margin-offset">
        <Button onClick={(e) => addImage(e)} variant={!fileName ? 'outline-success' : 'success '} disabled={!fileName}>
          Upload
        </Button>
      </Modal.Footer>
    </>
  );
};

SiteImageUpload.propTypes = {
  addImage: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
};

export default SiteImageUpload;
