import { getSiteHistory, getContactHistory } from '../api/site';
import paginationUpdate from './paginationUpdate';

const paginationHandler = ({
  pageNumber, limit = null, siteId, organizationId, pagination, setPagination, isSiteHistory = true, showToast,
}) => {
  const getHistory = () => {
    if (isSiteHistory) return getSiteHistory(siteId, organizationId, pageNumber, pagination.perPage);
    return getContactHistory(siteId, organizationId, pageNumber, pagination.perPage);
  };

  function paginationChange() {
    getHistory()
      .then((response) => response.json())
      .then(data => {
        paginationUpdate('next', data, setPagination);
      }).catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  }

  function limitFetch() {
    getContactHistory(siteId, organizationId, pageNumber, limit)
      .then((response) => response.json())
      .then(data => {
        paginationUpdate('limit', data, setPagination);
      }).catch((error) => {
        if (error.message.includes('Unexpected token')) {
          showToast({ message: 'Invalid option', type: 'error' });
        } else {
          showToast({ message: error.message, type: 'error' });
        }
      });
  }

  if (limit !== null) {
    limitFetch();
  } else {
    paginationChange();
  }
};

export default paginationHandler;
