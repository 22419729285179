import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import titleize from 'titleize';
import { styles } from './ContactShow.component.style';
import ContactInfo from './ContactInfo';
import ContactSite from './ContactSite';
import CustomFieldShow from '../../CustomField/CustomFieldShow';
import { deleteContact } from '../../../utils/api/contact';
import { redirectToContacts } from '../../../utils/navigation';
import { DialogsProvider, ToastProvider } from '../../../providers';
import { withContextProvider } from '../../../hoc';
import { useToasts } from '../../../hooks';
import { CurrentUserProvider } from '../../../providers/CurrentUserProvider';
import DialogModal from '../../DialogModal';

const ContactShow = ({
  contact,
  values,
  modelFormItems,
  sites,
  isManager,
}) => {
  // Hook Toast
  const { showToast } = useToasts();
  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
  });

  const removeContact = () => {
    const formData = {
      organization_id: contact.organization_id,
      id: contact.id,
    };

    deleteContact(formData, contact.id)
      .then((response) => {
        if (response.status === 200) {
          redirectToContacts();
        } else {
          throw new Error(response[0]);
        }
      })
      .catch((error) => {
        showToast({ message: error, type: 'error' });
      });
  };
  const handleCloseContactModal = () => {
    setAlertBody({ show: false });
  };
  const handleOpenDeleteContactModal = () => {
    const message = contact && contact.name
      ? `Are you sure you want to delete ${contact.name}?`
      : 'Are you sure you want to delete this contact?';

    setAlertBody({
      message,
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => { removeContact(); handleCloseContactModal(); },
        }, {
          label: 'No',
          variant: 'danger',
          onClick: handleCloseContactModal,
        },
      ],
    });
  };

  return (
    <CurrentUserProvider values={{ isManager }}>
      <DialogsProvider>
        <div className="row mx-auto d-flex justify-content-between" style={styles.titleRow}>
          <h1 className="ml-2">{titleize(contact.name)}</h1>
          <div className="mt-2 mr-2">
            <a href={`/contacts/${contact.id}/edit`} className="btn btn-success">
              Edit
            </a>
            {isManager ? (
              <button
                type="button"
                className="btn btn-danger ml-1"
                onClick={handleOpenDeleteContactModal}
              >
                Delete
              </button>
            ) : <></>}

          </div>
        </div>
        <div className="bg-light">
          <ContactInfo contact={contact} />

          <CustomFieldShow
            values={values}
            modelFormItems={modelFormItems}
            styles={styles}
          />
        </div>
        <ContactSite sites={sites} contact={contact} />
        <DialogModal
          isVisible={alertBody.show}
          title={alertBody.title}
          message={alertBody.message}
          onClose={handleCloseContactModal}
          actions={alertBody.actions}
        />

      </DialogsProvider>
    </CurrentUserProvider>
  );
};

ContactShow.propTypes = {
  contact: PropTypes.object.isRequired,
  values: PropTypes.object,
  modelFormItems: PropTypes.array,
  sites: PropTypes.array,
  isManager: PropTypes.bool.isRequired,
};

ContactShow.defaultProps = {
  values: {},
  modelFormItems: [],
  sites: [],
};

export default withContextProvider(ToastProvider)(ContactShow);
