import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import Icon from '@mdi/react';

/**
 * Select dropdown component to render multiple options with a single selection.
 * This component render an array of object in form of { name:"", value: "", label: "", icon: "" }.
 * Property `name` must be an string and property `value` can be string, number or object
 * @param enabledEmptyOption enables and empty option in the dropdown. By default this value
 * is `true`
 * @param emptyLabel represent an string with the name of the empty option. By default this
 * value is `None`
 */

const { Option } = components;
const IconOption = ({ data, ...props }) => {
  return (
    <Option {...props}>
      <Icon path={data.icon} size={1} /> {data.label}
    </Option>
  );
};
IconOption.defaultProps = {
  data: undefined,
};

IconOption.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      icon: PropTypes.string.isRequried,
    }).isRequired,
  ),
};

export default IconOption;
