import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import DialogModal from '../../../../DialogModal';
import { setPrimaryImage } from '../../../../../utils/api/site';
import SiteImagesCarousel from './SiteImagesCarousel';

const SiteImage = ({
  siteImages,
  siteId,
  organizationId,
}) => {
  const [primaryImgId, setPrimaryImageId] = useState(null);
  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
  });

  useEffect(() => {
    // primaryImgId is used to help fix the following edge case:
    // 1. A different image is set as the primary imag inside onSetPrimaryImage()
    // 2. The siteImages are not updated because backend doesn't return the updated images
    // 3. The primary image is deleted and causes images to no longer display
    if (siteImages && siteImages.length > 0) {
      const currentPrimaryImg = siteImages.find(img => img?.primary);
      if (currentPrimaryImg) {
        setPrimaryImageId(currentPrimaryImg.id);
      } else {
        // default to first image to avoid displaying nothing
        const firstImgId = siteImages[0]?.id;
        setPrimaryImageId(firstImgId);
        // API call to help prevent site cards from displaying nothing
        setPrimaryImage({ organization_id: organizationId }, siteId, firstImgId);
      }
    }
  }, [siteImages, siteId, organizationId]);

  const handleCloseImageModal = () => {
    setAlertBody({ show: false });
  };

  return (
    <div>
      <SiteImagesCarousel
        siteImages={siteImages}
        primaryImgId={primaryImgId}
      />
      <DialogModal
        isVisible={alertBody.show}
        title={alertBody.title}
        message={alertBody.message}
        onClose={handleCloseImageModal}
        actions={alertBody.actions}
      />
    </div>
  );
};

SiteImage.propTypes = {
  siteId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  siteImages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SiteImage;
