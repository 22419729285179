import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaEllipsisH } from 'react-icons/fa';
import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '../IconButton';
import { ICON_COLOR_GRAY, MEDIUM_ICON_SIZE } from '../../../constants/theme';

const MenuButton = ({
  items, iconColor, iconSize, isNewSite,
}) => {
  const actionItems = isNewSite ? [items[1]] : items;
  const [show, setShow] = useState(false);
  const itemClass = isNewSite ? 'justDelete' : 'three-dot-item';
  const itemLists = items && items.length && (
    <ul className="three-dot-list">
      {actionItems.map((item) => (
        <li
          className={itemClass}
          key={uuidv4()}
          onClick={(e) => {
            setShow(false);
            item.onClick(e);
          }}
          aria-hidden="true"
        >
          {item.label}
        </li>
      ))}
    </ul>
  );

  const menuPopover = (
    <BootstrapPopover id="popover-container">
      <BootstrapPopover.Content>{itemLists}</BootstrapPopover.Content>
    </BootstrapPopover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      containerPadding={20}
      overlay={menuPopover}
      onToggle={() => setShow(!show)}
      show={show}
    >
      <IconButton
        className="btn"
        icon={<FaEllipsisH color={iconColor} size={iconSize} />}
      />
    </OverlayTrigger>
  );
};

MenuButton.defaultProps = {
  items: [],
  iconColor: ICON_COLOR_GRAY,
  iconSize: MEDIUM_ICON_SIZE,
  isNewSite: false,
};

MenuButton.propTypes = {
  items: PropTypes.array,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  isNewSite: PropTypes.bool,
};

export default MenuButton;
