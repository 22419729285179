import React from 'react';
import { PropTypes } from 'prop-types';
import Linkify from 'react-linkify';
import { styles } from './ContactInfo.component.style';

const ContactInfo = ({
  contact,
}) => {
  return (
    <div style={styles.table}>
      <table>
        <tbody>
          <tr>
            <th className="col-md-2">Name:</th>
            <td> {contact.name} </td>
          </tr>
          <tr>
            <th className="col-md-2">Phone:</th>
            <td> {contact.phone} </td>
          </tr>
          <tr>
            <th className="col-md-2">Company:</th>
            <td> {contact.company} </td>
          </tr>
          <tr>
            <th className="col-md-2">Contact Type:</th>
            <td> {contact.contact_type} </td>
          </tr>
          <tr>
            <th className="col-md-2">Address Line 1:</th>
            <td> {contact.address1} </td>
          </tr>
          <tr>
            <th className="col-md-2">Address Line 2:</th>
            <td> {contact.address2} </td>
          </tr>
          <tr>
            <th className="col-md-2">City:</th>
            <td> {contact.city} </td>
          </tr>
          <tr>
            <th className="col-md-2">State:</th>
            <td> {contact.state} </td>
          </tr>
          <tr>
            <th className="col-md-2">Zip Code:</th>
            <td> {contact.zip_code} </td>
          </tr>
          <tr>
            <th className="col-md-2">Email:</th>
            <td> {contact.email} </td>
          </tr>
          <tr>
            <th className="col-md-2">Note:</th>
            <td><Linkify> {contact.note} </Linkify></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default ContactInfo;
