import React, {
  useState, useEffect,
} from 'react';
import { PropTypes } from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { addSiteToTag } from '../../../../utils/api/tag';
import { removeTag } from '../../../../utils/api/site';
import { styles } from './SiteTag.component.style';
import { useDialogs, useToasts } from '../../../../hooks';
import IconButton from '../../../FormInputs/IconButton';

const SiteTags = ({ site, organizationTags }) => {
  // Hook Toast
  const { showToast } = useToasts();

  const [assignedTags, setAssignedTags] = useState(site.tags || []);
  const [unassignedTags, setUnassignedTags] = useState(
    organizationTags || [],
  );
  const [selectedValue, setSelectedValue] = useState('');
  const { openDialog, closeDialog } = useDialogs();

  useEffect(() => {
    if (site.tags && site.tags.length > 0) {
      const newUnassignedTags = organizationTags.filter(
        (elem) => !site.tags.find(({ id }) => elem.id === id),
      );
      setUnassignedTags(newUnassignedTags);
    }
    setAssignedTags(site.tags);
  }, [site, organizationTags]);

  const onSelectTag = (e) => {
    e.preventDefault();
    setSelectedValue(e.target.value);
  };

  const onAddTag = (e) => {
    e.preventDefault();
    const formData = {
      organization_id: site.organization_id,
      tag_site: {
        organization_id: site.organization_id,
        site_id: site.id,
      },
    };

    addSiteToTag(formData, selectedValue)
      .then((response) => response.json())
      .then((data) => {
        setAssignedTags([...assignedTags, data.tag]);
        setUnassignedTags(
          unassignedTags.filter(({ id }) => id !== data.tag.id),
        );
      })
      .then(setSelectedValue(''))
      .catch((error) => {
        if (error.message.includes('JSON')) {
          showToast({ message: 'Please be sure tag exists', type: 'error' });
        } else {
          showToast({ message: error.message, type: 'error' });
        }
      });
  };

  const onDelete = (e, tagId, tagName) => {
    e.preventDefault();
    removeTag(tagId, site.id, site.organization_id)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setAssignedTags(assignedTags.filter(({ id }) => id !== tagId));
          setUnassignedTags([
            ...unassignedTags,
            { id: tagId, name: tagName },
          ]);
        }
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  const handleOpenDeleteModal = (e, tagId, tagName) => {
    openDialog({
      message: 'Are you sure you want to delete this tag from the site?',
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            onDelete(e, tagId, tagName);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  return (
    <div className="row mx-1">
      <>
        <div className="input-group mb-3">
          <select
            className="custom-select"
            value={selectedValue}
            id="inputGroupSelect"
            style={styles.selectTag}
            onChange={(e) => onSelectTag(e)}
          >
            <option value="" selected disabled>
              Choose tag
            </option>
            {unassignedTags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
          <div className="input-group-append">
            <button
              type="button"
              onClick={(e) => onAddTag(e)}
              className="btn btn-outline-secondary"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="mb-3">
          <b>Tags: </b>
          {assignedTags?.map((tag) => (
            <div key={tag.id} className="group-badge">
              {tag.name}
              <IconButton
                icon={<FaTimes className="text-danger fa fa-lg" />}
                onClick={(e) => handleOpenDeleteModal(e, tag.id, tag.name)}
              />
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

SiteTags.propTypes = {
  site: PropTypes.object.isRequired,
  organizationTags: PropTypes.array.isRequired,
};

export default SiteTags;
