import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import SiteNoteItem from './SiteNoteItem';
import { redirectToSiteNoteNew } from '../../../../utils/navigation';
import { removeNote, returnTextBasedOnCount, getPriorityColor } from '../../../../utils/api/site';
import { styles } from './SiteNote.component.style';

const SiteNotes = ({
  site,
}) => {
  const [notes, setNotes] = useState([]);
  const siteNotesHeaderText = returnTextBasedOnCount(notes.length, 'Note');

  useEffect(() => {
    setNotes(site.notes || []);
  }, [site]);

  const onCreate = (e) => {
    e.preventDefault();
    redirectToSiteNoteNew(site.id);
  };

  const onDeleteNote = (e, noteId) => {
    e.preventDefault();
    removeNote({ organization_id: site.organization_id }, noteId)
      .then(() => {
        setNotes(notes.filter(note => note.id !== noteId));
      });
  };

  return (
    <div>
      <div className="site-visits-notes-title" style={styles.noteTitle}>
        <h3 className="title-with-count">
          {siteNotesHeaderText}
        </h3>
        <button type="button" className="m-1 btn btn-outline-light" onClick={(e) => onCreate(e)}>
          <i className="fa fa-2x fa-plus" style={styles.onCreateIcon} />
        </button>
      </div>
      {notes.map((note) => (
        <SiteNoteItem
          key={note.id}
          title={note.title}
          content={note.content}
          status={note.status}
          color={getPriorityColor(note.priority)}
          id={note.id}
          timestamp={note.timestamp}
          siteId={site.id}
          onDeleteNote={onDeleteNote}
          organizationId={site.organization_id}
        />
      ))}

    </div>
  );
};

SiteNotes.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SiteNotes;
