import React from 'react';
import { PropTypes } from 'prop-types';
import {
  redirectToContact, redirectToContactEdit, redirectToSiteEditElement,
} from '../../../../utils/navigation';
import { removeContactsFromSite } from '../../../../utils/api/site';
import SiteContactList from './SiteContactList';
import SiteContactHeader from './SiteContactHeader';
import { useToasts } from '../../../../hooks';

const SiteContacts = ({ siteData, setSiteData }) => {
  // Hook Toast
  const { showToast } = useToasts();

  const contacts = siteData.contacts || [];

  const onShow = (e, id) => {
    e.preventDefault();
    redirectToContact(id);
  };

  const onCreate = (e, siteId, scrollObj) => {
    e.preventDefault();
    redirectToSiteEditElement(siteId, scrollObj.id, scrollObj.scrollTo);
  };

  const onEdit = (e, id) => {
    e.preventDefault();
    redirectToContactEdit(id);
  };

  const onDelete = (e, contactId) => {
    e.preventDefault();
    const formData = {
      organization_id: siteData.organization_id,
      contacts: [
        { id: contactId },
      ],
    };

    removeContactsFromSite(formData, siteData.id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.error);
        }
        setSiteData(prev => ({ ...prev, contacts: contacts.filter(contact => contact.id !== contactId) }));
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  return (
    <div>
      <SiteContactHeader
        siteId={siteData.id}
        contacts={contacts}
        onCreate={onCreate}
      />
      <div className="mb-3">
        <SiteContactList
          contacts={contacts}
          onShow={onShow}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
};

SiteContacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  siteData: PropTypes.object.isRequired,
  setSiteData: PropTypes.func.isRequired,
};

export default SiteContacts;
