// this function returns portions of an array based on specified start and end points
export const range = (start, end) => {
  // "end" defines the length of the returned array, while "start" acts as an offset
  return [...Array(end).keys()].map((element) => element + start);
};

// this function handles the range of page numbers shown within the
// pagination div based on the value passed in for pagesCutCount
export const cutPages = ({ pagesCount, pagesCutCount, currentPage }) => {
  const ceiling = Math.ceil(pagesCutCount / 2);
  const floor = Math.floor(pagesCutCount / 2);
  if (pagesCount < pagesCutCount) {
    return { start: 1, end: pagesCount };
  } if (currentPage === 1 && pagesCount >= pagesCutCount) {
    return { start: currentPage, end: pagesCutCount };
  } if (currentPage > 1 && currentPage < ceiling) {
    return { start: currentPage - 1, end: pagesCutCount };
  } if (currentPage > 1 && currentPage === ceiling) {
    return { start: (currentPage - floor), end: pagesCutCount };
  } if (currentPage + floor >= pagesCount && currentPage !== pagesCount) {
    return { start: currentPage - floor - 1, end: pagesCutCount };
  } if (currentPage === pagesCount) {
    return { start: currentPage - ceiling - 1, end: pagesCutCount };
  }
  return { start: currentPage - 1, end: pagesCutCount };
};
