import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { DialogsProvider, ToastProvider } from '../../../providers';
import { withContextProvider } from '../../../hoc';

import Tabs from './Tabs';

import SitePhotosTab from './Tabs/SitePhotosTab';
import SiteDetailsTab from './Tabs/SiteDetailsTab';
import { useToasts } from '../../../hooks';

const SiteForm = ({
  site, location, siteContacts, orgContacts, organization, initialModalFormItems, values,
}) => {
  const { showToast } = useToasts();
  const [activeTab, setActiveTab] = useState(localStorage.getItem('editTab') || 'details');
  if (localStorage.getItem('editTab')) {
    localStorage.removeItem('editTab');
    showToast({ message: 'Changes saved successfully', type: 'success' });
  }
  const [isHiddenSaveChangesModal, setIsHiddenSaveChangesModal] = useState(true);
  const tabs = [
    {
      title: 'Details',
      key: 'details',
    },
  ];

  if (site?.id) {
    tabs.push({
      title: 'Photos',
      key: 'photos',
    });
  }

  return (
    <DialogsProvider>
      <div>
        <form autoComplete="off">
          <div className="form-group">
            {site?.id ? (
              <h2>Edit Site Information</h2>
            ) : (
              <h2>Create a Site</h2>
            )}
          </div>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          {(activeTab === 'details' || !isHiddenSaveChangesModal) && (
            <>
              <SiteDetailsTab
                site={site}
                location={location}
                siteContacts={siteContacts}
                orgContacts={orgContacts}
                organization={organization}
                initialModalFormItems={initialModalFormItems}
                values={values}
                activeTab={activeTab}
                isHiddenSaveChangesModal={isHiddenSaveChangesModal}
                setIsHiddenSaveChangesModal={setIsHiddenSaveChangesModal}
              />
            </>
          )}

          {activeTab === 'photos' && site.id && (
            <>
              <SitePhotosTab
                site={site}
                organization={organization}
              />
            </>
          )}
        </form>
      </div>
    </DialogsProvider>
  );
};

SiteForm.propTypes = {
  site: PropTypes.object,
  location: PropTypes.object,
  siteContacts: PropTypes.array,
  orgContacts: PropTypes.array,
  organization: PropTypes.object,
  initialModalFormItems: PropTypes.array,
  values: PropTypes.object,

};

SiteForm.defaultProps = {
  site: {},
  location: undefined,
  siteContacts: [],
  orgContacts: [],
  organization: {},
  initialModalFormItems: [],
  values: {},
};

export default withContextProvider(ToastProvider)(SiteForm);
