import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Option from './Option';
import { capitalizeFirstLetter } from '../../../../../utils/api/index';

const MultiSelect = ({ item, initiallySelectedOptions, onOptionsChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(initiallySelectedOptions);
  const label = capitalizeFirstLetter(item.label);

  const onOptionChange = (option, selected) => {
    if (selected) {
      const updatedOptions = [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
      onOptionsChange(item.key, JSON.stringify(updatedOptions));
    } else {
      const filteredOptions = selectedOptions.filter(opt => opt !== option);
      setSelectedOptions(filteredOptions);
      onOptionsChange(item.key, JSON.stringify(filteredOptions));
    }
  };

  return (
    <fieldset>
      <label>{label}</label>
      {item.values.map((option, index) => (
        <Option
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          option={option.value}
          onOptionChange={onOptionChange}
          selectedOptions={selectedOptions}
        />
      ))}
    </fieldset>
  );
};

MultiSelect.propTypes = {
  item: PropTypes.object.isRequired,
  initiallySelectedOptions: PropTypes.array.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
};

export default MultiSelect;

