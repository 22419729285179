import React from 'react';
import { PropTypes } from 'prop-types';
import { styles } from './SiteContactHeader.component.style';
import { returnTextBasedOnCount } from '../../../../../utils/api/site';

const SiteContactHeader = ({
  siteId, contacts, onCreate,
}) => {
  const siteContactHeaderText = returnTextBasedOnCount(contacts.length, 'Contact');
  return (
    <div className="site-visits-notes-title" style={styles.contactsTitle}>
      <h3 className="title-with-count">
        {siteContactHeaderText}
      </h3>
      <button type="button" className="m-1 btn btn-outline-light" style={styles.createDiv} onClick={(e) => onCreate(e, siteId, { id: 'contacts', scrollTo: true })}>
        <i className="fa fa-2x fa-plus" style={styles.createIcon} />
      </button>
    </div>
  );
};

SiteContactHeader.defaultProps = {
  siteId: null,
};

SiteContactHeader.propTypes = {
  siteId: PropTypes.number,
  contacts: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default SiteContactHeader;
