import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PropTypes } from 'prop-types';
import ContactHistoryTable from './ContactHistoryTable';
import Pagination from '../../../../../utils/pagination/PaginationComponent';
import { INITIAL_PAGINATION } from '../../../../../constants/pagination';
import { useToasts } from '../../../../../hooks';
import paginationUpdate from '../../../../../utils/pagination/paginationUpdate';
import paginationHandler from '../../../../../utils/pagination/paginationHandler';

const ContactHistory = ({ siteData, siteId, organizationId }) => {
  const [contactPagination, setContactPagination] = useState(INITIAL_PAGINATION);
  const { showToast } = useToasts();
  const prevContacts = useRef(siteData.contacts);

  useEffect(() => {
    if (siteData.contact_histories) paginationUpdate('fetch', siteData.contact_histories, setContactPagination);
  }, [siteData.contact_histories]);

  const setCurrentLimit = (event) => {
    paginationHandler({
      pageNumber: 1,
      limit: event.target.value,
      siteId,
      organizationId,
      pagination: contactPagination,
      setPagination: setContactPagination,
      isSiteHistory: false,
      showToast,
    });
  };

  const setCurrentPage = (number) => {
    paginationHandler({
      pageNumber: number,
      siteId,
      organizationId,
      pagination: contactPagination,
      setPagination: setContactPagination,
      isSiteHistory: false,
      showToast,
    });
  };

  useEffect(() => {
    // check if siteData.contacts has changed
    if (siteData.contacts !== prevContacts.current) {
      paginationHandler({
        pageNumber: 1,
        siteId,
        organizationId,
        pagination: contactPagination,
        setPagination: setContactPagination,
        isSiteHistory: false,
        showToast,
      });
      // update prevContacts to the latest value of siteData.contacts
      prevContacts.current = siteData.contacts;
    }
  }, [siteData.contacts, contactPagination, organizationId, showToast, siteId]);

  return (
    <>
      {
        contactPagination?.currentHistories?.length
          ? (
            <div>
              <Container>
                <Row>
                  <Col
                    className="site-update-history"
                  >
                    <Pagination
                      paginationObject={contactPagination}
                      // setCurrentPage={ContactPaginationHandler}
                      handleChange={setCurrentLimit}
                      setCurrentPage={setCurrentPage}
                      label="Contact Histories Per Page"
                    >Contact Update History
                    </Pagination>
                  </Col>
                </Row>
              </Container>
              <ContactHistoryTable
                ContactHistories={contactPagination.currentHistories}
                organizationId={organizationId}
              />
            </div>
          ) : <></>
      }
    </>
  );
};

ContactHistory.propTypes = {
  siteData: PropTypes.object.isRequired,
  siteId: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired,
};
export default ContactHistory;
