import React, {
  useCallback, useEffect, useMemo, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ToastContext } from '../context';
import toastReducer from '../reducers/toasts';
import { addToast, removeToast } from '../actions/toasts';
import Toast from '../components/Toasts';

const INIT_STATE = [];
const TOAST_DURATION = 3000; // 3 seconds

export const ToastProvider = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, INIT_STATE);

  /**
   * This effect will remove a toast from stack
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (toasts.length) {
        const { id } = toasts[toasts.length - 1];
        dispatch(removeToast(id));
      }
    }, TOAST_DURATION);

    return () => clearInterval(timer);
  }, [toasts]);

  /**
   * Add toast
   */
  const showToast = useCallback((toast) => {
    dispatch(addToast({
      id: uuidv4(),
      ...toast,
    }));
  }, []);

  /**
   * Hide toast by id
   */
  const hideToast = useCallback((id) => {
    dispatch(removeToast(id));
  }, []);

  const provider = useMemo(() => ({ showToast, hideToast }), [showToast, hideToast]);

  return (
    <ToastContext.Provider value={provider}>
      {children}
      <div className="toast-container">
        { toasts.map(toast => (
          <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => hideToast(toast.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

