import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Modal } from 'react-bootstrap';
import PhotoGallery from '../../PhotoGallery';
import SiteImageUpload from '../../../SiteShow/SiteView/SiteImage/SiteImageUpload';
import { getSiteInfo, addImageToSite } from '../../../../../utils/api/site';
import { DialogsProvider, ToastProvider } from '../../../../../providers';
import { withContextProvider } from '../../../../../hoc';
import { useToasts } from '../../../../../hooks';

const SitePhotosTab = ({ site, organization, setNewSiteImages }) => {
  // Hooks are used for uploading image
  const [image, setImage] = useState(null);
  const [expandUploadModal, setExpandModal] = useState(false);
  // Hook Toast
  const { showToast } = useToasts();

  const [siteImages, setSiteImages] = useState([]);

  useEffect(() => {
    if (!site?.id) {
      return () => {};
    }
    getSiteInfo(site?.id, organization?.id)
      .then((response) => response.json())
      .then((data) => {
        if (!data.site) {
          throw new Error(data.error);
        }
        setSiteImages(data.site.site_images);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
    return () => {};
  }, [site?.id, organization?.id, showToast]);

  const addImage = (e) => {
    e.preventDefault();

    const siteImage = site.id
      ? {
        site_image: {},
        image: `data:image/png;base64,${image}`,
      }
      : {
        id: siteImages.length,
        image: {
          url: `data:image/png;base64,${image}`,
        },
      };
    if (site.id) {
      addImageToSite(siteImage, site.id, organization.id)
        .then((response) => response.json())
        .then((data) => {
          // added error message if image is not uploaded
          if (!data.site_image) {
            throw new Error(data[0]);
          }
          setSiteImages((images) => [...images, data.site_image]);
          showToast({ message: 'Image is successfully added', type: 'success' });
        })
        .catch((error) => {
          showToast({ message: error.message, type: 'error' });
        });
    } else {
      setNewSiteImages(siteImage);
      setSiteImages(prev => [...prev, siteImage]);
    }
    return setExpandModal(false);
  };

  const onExpand = (e) => {
    e.preventDefault();
    const value = !expandUploadModal;
    setExpandModal(value);
  };

  const handleOnload = (readerEvt) => {
    const binaryString = readerEvt.target.result;
    setImage(window.btoa(binaryString));
  };

  const getImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = handleOnload;
      reader.readAsBinaryString(file);
    }
  };

  return (
    <DialogsProvider>
      <div className="d-flex justify-content-between pt-3 pb-3 align-items-center">
        {siteImages.length > 1 && site.id && <p>Drag and drop images to change the order.</p>}
        <button
          type="button"
          className="btn btn-outline-success"
          onClick={(e) => onExpand(e)}
        >
          Upload photo
        </button>
      </div>
      <Modal
        show={expandUploadModal}
        onHide={() => setExpandModal(false)}
        animation={false}
      >
        <Modal.Header closeButton className="mb-3">
          <h5 className="text-center">Upload Site Image</h5>
        </Modal.Header>
        <Modal.Body>
          <SiteImageUpload
            addImage={addImage}
            getImage={getImage}
            cancel={onExpand}
            siteId={site.id}
          />
        </Modal.Body>
      </Modal>
      <PhotoGallery
        siteImages={siteImages}
        setSiteImages={setSiteImages}
        organizationId={organization.id}
        siteId={site.id}
      />
    </DialogsProvider>
  );
};

SitePhotosTab.propTypes = {
  site: PropTypes.object,
  organization: PropTypes.object,
  setNewSiteImages: PropTypes.func.isRequired,
};

SitePhotosTab.defaultProps = {
  site: {},
  organization: {},
};

export default withContextProvider(ToastProvider)(SitePhotosTab);
