import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import {
  Modal, Form, Row, Col,
} from 'react-bootstrap';
import SiteDatePicker from '../FormInputs/DateSelector';
import SpinnerButton from '../FormInputs/SpinnerButton';
import { createRoute, updateRoute, getRoutes } from '../../utils/api/routes';
import { redirectToRouteEdit } from '../../utils/navigation';
import { useToasts } from '../../hooks';
import { withContextProvider } from '../../hoc';
import { ToastProvider } from '../../providers';
import Popover from '../Popover';
import { handleResponse } from '../../utils/api/errorHandler';

const defaultValues = {
  name: '',
  description: '',
};

/**
 * Modal form to create a new route
 */
const RouteModal = ({
  isOpen, organizationId, currentRoute, onClose,
}) => {
  // State to handle form inputs
  const [formInput, setFormInput] = useState(defaultValues);

  // State to handle date selected
  const [date, setDate] = useState(new Date());

  // State to handle loading bool
  const [loading, setLoading] = useState(false);

  // Ref to check first render
  const firstRender = useRef(true);

  // State to handle errors in form
  const [errors, setErrors] = useState({});

  // Hook Toast
  const { showToast } = useToasts();

  // State to handle popover
  const popoverRef = React.useRef();
  const [popoverContent, setPopoverContent] = useState({});
  const [showPopover, setShowPopover] = useState(false);

  // Title and Button value is set based on if the currentRoute value exists
  const title = currentRoute ? 'Edit Route' : 'New Route';
  const submitButtonText = currentRoute ? 'Save' : 'Create';

  /**
   * Handle text input changes
   * @param {*} e HTML event
   */
  const handleOnChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Validate required data in form
   */
  const handleValidation = useCallback(() => {
    const errorsFound = {};

    // Check if name is empty
    if (!formInput.name.trim().length) {
      errorsFound.name = 'Name is required';
    }

    // Update errors state
    setErrors(errorsFound);

    return Object.keys(errorsFound).length;
  }, [formInput.name]);

  /**
   * Effect to set formItem if there is route given
   */
  useEffect(() => {
    // set formItem if there is route info for editing otherwise use defaultValues
    if (currentRoute) {
      setFormInput(currentRoute);
      setDate(currentRoute.date);
    }
  }, [currentRoute]);

  /**
   * Effect to validate required data in hot
   */
  useEffect(() => {
    // Skip validation on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // Validate data
    handleValidation();
  }, [formInput.name, handleValidation]);

  /**
   * Handle submit, sending data to endpoint to create a route.
   * If response is 201 redirect user to /edit page
   */
  const handleSubmit = async () => {
    // Check for errors before continue
    if (handleValidation()) {
      showToast({ message: 'Missing information required', type: 'error' });
      return;
    }

    const formData = {
      ...formInput,
      date: new Date(date),
    };
    try {
      setLoading(true);

      // switch between create and update based on if the route exists, then make an API call
      const apiRequest = currentRoute ? updateRoute : createRoute;
      const data = currentRoute ? { route: formData } : formData;
      const routeId = currentRoute ? currentRoute.routeId : null;
      const response = await apiRequest(data, organizationId, routeId);
      const responseData = await handleResponse(response);
      const toastMessage = currentRoute ? 'Route updated successfully' : 'Route added successfully';
      if (responseData) {
        const { route } = responseData;
        showToast({ message: `${toastMessage}`, type: 'success' });
        // Redirect user to edit page
        redirectToRouteEdit(route.id);
      }
    } catch (error) {
      // Ups something happened
      showToast({ message: error.message, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Make api call to check if Route name is already in used. If so, a confirmation popover will be displayed.
   * If user select YES, a route with a existing name will be created.
   * If user select NO, name field will be cleared and user should need to enter a different name
   */
  const handleVerification = async () => {
    // if the route name was changed - run through name verification or skip to submit
    const isNameTheSame = currentRoute?.name?.toLowerCase() === formInput.name.toLowerCase();
    if (isNameTheSame) {
      handleSubmit();
      return;
    }

    try {
      setLoading(true);

      const routeName = formInput.name.toLowerCase();

      const params = { routeName, routesStartDate: '', routesEndDate: '' };
      const response = await getRoutes(organizationId, params);
      const data = await handleResponse(response);

      const isFound = data.routes.some(route => route.name.toLowerCase() === routeName);

      // Route name not found. The route will be posted
      if (!isFound) {
        handleSubmit();
        return;
      }

      setPopoverContent({
        title: 'Are you sure?',
        message: 'A route by this name already exists. Proceed with the same name?',
        actions: [
          {
            label: 'Yes',
            onClick: () => {
              setShowPopover(false);
              handleSubmit();
            },
          },
          {
            label: 'No',
            variant: 'danger',
            onClick: () => {
              setShowPopover(false);
              setFormInput({ ...formInput, name: '' });
              setPopoverContent({});
            },
          },
        ],
      });

      setShowPopover(true);
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Close modal and reset form and error state
   */
  const handleClose = () => {
    // Reset state
    firstRender.current = true;
    if (currentRoute) {
      setFormInput(currentRoute);
      setDate(currentRoute.date);
    } else {
      setFormInput(defaultValues);
      setDate(new Date());
    }
    setErrors({});

    onClose();
  };

  return (
    <div>
      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="routeForm">
              <Row>
                <Col sm={4}>
                  <Form.Label>Route Name (*)</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="name"
                    required
                    value={formInput.name}
                    isInvalid={!!errors.name}
                    onChange={handleOnChange}
                    ref={popoverRef}
                    disabled={showPopover}
                  />

                  <Form.Control.Feedback type="invalid">
                    { errors.name }
                  </Form.Control.Feedback>

                  <Popover
                    shouldShow={showPopover}
                    ref={popoverRef}
                    content={popoverContent}
                  />

                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="routeForm">
              <Row>
                <Col sm={4}>
                  <Form.Label>Date</Form.Label>
                </Col>
                <Col sm={8}>
                  <SiteDatePicker
                    name="date"
                    value={date}
                    setValue={setDate}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={3}
                value={formInput.description}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <SpinnerButton
            label={submitButtonText}
            labelLoading="Saving..."
            isLoading={loading}
            onClick={handleVerification}
          />
        </Modal.Footer>
      </Modal>

    </div>
  );
};

RouteModal.defaultProps = {
  isOpen: false,
  currentRoute: null,
};

RouteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  currentRoute: PropTypes.object,
};

export default withContextProvider(ToastProvider)(RouteModal);
