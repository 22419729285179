import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const DragDropContextComponent = ({
  draggableItems, onDragEnd, getListStyle,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {draggableItems}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragDropContextComponent.propTypes = {
  draggableItems: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  getListStyle: PropTypes.func.isRequired,
};

export default DragDropContextComponent;
