import React from 'react';
import titleize from 'titleize';
import { PropTypes } from 'prop-types';
import { ToastProvider } from '../../../providers';
import { withContextProvider } from '../../../hoc';
import SeasonItem from '../SeasonItem';
import SeasonHeader from '../SeasonHeader';

const SeasonIndex = ({
  season,
  isManager,
  organization,
}) => {
  return (
    <div>
      <div className="d-flex flex-row justify-content-between pt-4 pb-4">
        <h3>Season {titleize(season.name)} Information</h3>
      </div>

      <table className="table table-striped table-sm" style={{ borderBottom: 'solid 2px lightgray' }}>
        <SeasonHeader />

        <tbody>
          <SeasonItem season={season} isManager={isManager} organization={organization} />
        </tbody>
      </table>
    </div>
  );
};

SeasonIndex.propTypes = {
  season: PropTypes.object.isRequired,
  isManager: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
};

export default withContextProvider(ToastProvider)(SeasonIndex);
