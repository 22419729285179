import {
  post, get, patch, destroy,
} from './index';

/**
 * Send request to create a new route
 * @param {object} data route data
 * @param {number} orgId organization id
 * @returns Promise with http response
 */
export const createRoute = (data, orgId) => post(`/api/v1/routes?organization_id=${orgId}`, data);

/**
 * Send request to get a routes
 * @param {number} orgId organization id
 * @param {object} params api params for searching by name, start and end date
 * @returns Promise with http response regarding getting routes
 */
export const getRoutes = (orgId, params) => {
  const queryParamString = new URLSearchParams({
    name: params.routeName,
    start_date: params.routesStartDate,
    end_date: params.routesEndDate,
    sort_by: params.sort_by,
    direction: params.direction,
    organization_id: orgId,
  }).toString();

  return get(`/api/v1/routes?${queryParamString}`);
};

/**
 * Send request to update a route
 * @param {*} data new route data
 * @param {*} orgId organization id
 * @param {*} routeId route id
 * @returns Promise with http response regarding editing a route
 */
export const updateRoute = (data, orgId, routeId) => patch(`/api/v1/routes/${routeId}?organization_id=${orgId}`, data);

/**
 * Send request to remove a route
 * @param {*} routeId route id
 * @param {*} orgId organization id
 * @returns Promise with http response regarding removing a route
 */
export const removeRoute = (routeId, orgId) => destroy(`/api/v1/routes/${routeId}?organization_id=${orgId}`);

/**
 * Send request to remove a stop route
 * @param {*} routeId route id
 * @param {*} data stop data
 * @returns Promise with http response
 */
export const removeStopRoute = (routeId, stopId, data) => destroy(`/api/v1/routes/${routeId}/route_stops/${stopId}`, data);
/**
 * Send request to reorder route stops
 * @param {*} routeId route id
 * @param {*} id route stop id
 * @param {*} orgId organization id
 * @param {*} newPosition new position value
 * @returns Promise with http response
 */
export const reorderRouteStops = (routeId, id, orgId, newPosition) => patch(`/api/v1/routes/${routeId}/route_stops/${id}?organization_id=${orgId}&new_position=${newPosition}`);
/**
 * Send request to get a single route
 * @param {number} orgId organization id
 * @param {number} routeId route id
 * @returns Promise with http response regarding single route
 */
export const getRoute = (orgId, routeId) => get(`/api/v1/routes/${routeId}?organization_id=${orgId}`);

/**
 * Send request to add a new site into a route
 * @param {number} orgId organization id
 * @param {number} routeId route id
 * @param {object} data stop data
 * @returns Promise with http response regarding adding a stop
 */
export const addSiteToRoute = (orgId, routeId, data) => post(`/api/v1/routes/${routeId}/route_stops?organization_id=${orgId}`, data);

/**
 * Send request to add a new site into a route
 * @param {array} stops
 * @returns url string
 */

export const generateGoogleMapURL = (stops) => {
  try {
    if (stops.length < 2) throw new Error('Not enough stops');
    const baseURL = 'https://www.google.com/maps/dir/?api=1';
    const origin = `&origin=${stops[0].location.latitude},${stops[0].location.longitude}`;
    const destination = `&destination=${stops[stops.length - 1].location.latitude},${stops[stops.length - 1].location.longitude}`;
    const travelMode = '&travelmode=driving';
    let waypoint = '&waypoints=';
    const waypointArray = [...stops];
    waypointArray.shift();
    waypointArray.pop();
    waypointArray.map(point => {
      waypoint += `${point.location.latitude},${point.location.longitude}|`;
      return waypoint;
    });
    return (baseURL + origin + destination + travelMode + waypoint);
  } catch (e) {
    // console.error(e);
    return undefined;
  }
};
