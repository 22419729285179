import React from 'react';
import { PropTypes } from 'prop-types';

const arrowStyles = {
  borderColor: 'black',
  backgroundColor: 'rgb(1, 133, 68)',
  padding: 5,
  top: '50%',
  bottom: 'auto',
  transform: 'translateY(-50%)',
};

const CarouselControlButton = ({ title, type }) => {
  return (
    <a
      className={`carousel-control-${type}`}
      href="#carouselExampleControls"
      role="button"
      data-slide={type}
      style={arrowStyles}
    >
      <span className={`carousel-control-${type}-icon`} aria-hidden="true" />
      <span className="sr-only">{title}</span>
    </a>
  );
};

CarouselControlButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CarouselControlButton;
