import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const PaginationItem = ({
  page, currentPage, onPageChange, isDisabled,
}) => {
  const liClasses = classNames({
    'page-item': true,
    active: page === currentPage,
    disabled: isDisabled,
  });
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <li
      className={liClasses}
      onClick={() => onPageChange(page)}
    >
      <span
        className="page-link"
      >{page}
      </span>
    </li>
  );
};

PaginationItem.defaultProps = {
  isDisabled: false,
};

PaginationItem.propTypes = {
  page: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default PaginationItem;
