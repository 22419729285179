import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

const Option = ({ option, selectedOptions, onOptionChange }) => {
  const [IsSelected, setSelected] = useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setSelected(selectedOptions.includes(option));
  }, [option, selectedOptions]);

  const handleChange = () => {
    setSelected(!IsSelected);
    onOptionChange(option, !IsSelected);
  };

  return (
    <div>
      <input
        type="checkbox"
        id={option}
        name="option name"
        value={option}
        onChange={handleChange}
        checked={IsSelected}
      />
      <label className="ml-1" htmlFor={option}>{option}</label>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.string.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default Option;
