/**
 * NOTE: This is not a map. This is the component which comunicate Rails + React
 * This component displays current sites for a single organization in a map.
 */
import React from 'react';
import { PropTypes } from 'prop-types';
import VamosMap from '../../VamosMap';
import SitesInMarkers from '../../VamosMap/SitesInMarkers';

const SitesInMap = ({ sitesArray }) => {
  return (
    <div style={{ marginBottom: '100px' }}>
      <VamosMap>
        <SitesInMarkers sitesArray={sitesArray} />
      </VamosMap>
    </div>
  );
};
SitesInMap.propTypes = {
  sitesArray: PropTypes.array.isRequired,
};
export default SitesInMap;

