import React from 'react';
import { PropTypes } from 'prop-types';
import { capitalizeFirstLetter } from '../../../../../../utils/api/index';

const TrForMultiSelect = ({ item, values, styles }) => {
  const label = capitalizeFirstLetter(item.label);

  const renderValues = () => {
    if (values[item.key] && Object.keys(values).length > 0) {
      const stringValues = JSON.stringify(values[item.key]);

      return JSON.parse(stringValues).join(', ');
    }
    return [];
  };

  return (
    <tr>
      <th className={styles.thClassName.className}> {`${label}:`}</th>
      <td>{renderValues(values)}</td>
    </tr>
  );
};

TrForMultiSelect.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default TrForMultiSelect;
