import React from 'react';
import { PropTypes } from 'prop-types';
import TrWithLabelNoValue from '../TableComponents/TrWithLabelNoValue';

const Header = ({ item, styles }) => {
  return (
    <TrWithLabelNoValue item={item} styles={styles} />
  );
};

Header.propTypes = {
  item: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Header;
