import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

/**
 * Component that renders a button with a spinner when is required.
 * This component can be customize with bootstrap classes
 */
const SpinnerButton = React.forwardRef(({
  label,
  labelLoading,
  isLoading,
  className,
  ...props
}, ref) => {
  /**
   * Display custom label depending on status of loading
   * @returns {string} label
   */
  const showLabel = useCallback(() => {
    if (isLoading && labelLoading) {
      return labelLoading;
    }

    return label;
  }, [isLoading, label, labelLoading]);

  /**
   * This effect will fire everytime isLoading is update
   */
  useEffect(() => {
    showLabel();
  }, [isLoading, labelLoading, showLabel]);

  return (
    <button
      ref={ref}
      type="button"
      className={`btn btn-primary ${className || ''}`}
      disabled={isLoading}
      {...props}
    >
      { isLoading && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden={isLoading ? 'true' : 'false'}
      />
      )}
      <span className="pr-2 pl-2">
        {showLabel()}
      </span>
    </button>
  );
});

SpinnerButton.defaultProps = {
  isLoading: false,
  labelLoading: undefined,
  className: undefined,
};

SpinnerButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelLoading: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default SpinnerButton;
