import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Icon from '@mdi/react';
import { mdiAccountArrowRight } from '@mdi/js';
import { PropTypes } from 'prop-types';
import { redirectToSiteVisit, redirectToSiteVisitEdit } from '../../../../../utils/navigation';
import { formatShortDate } from '../../../../../utils/dates';
import { styles } from './SiteVisitItem.component.style';
import MenuButton from '../../../../FormInputs/MenuButton';
import { useCurrentUser, useDialogs } from '../../../../../hooks';
import { accessToDeleteBtn } from '../../../../../utils/accessToDeleteBtn';

const SiteVisitItem = ({
  leader,
  purpose,
  workersInteracted,
  workersTotal,
  visitors,
  donations,
  needsConcerns,
  otherNotes,
  id,
  date,
  siteId,
  onDeleteVisit,
  completed,
}) => {
  const [open, setOpen] = useState(false);
  const { openDialog, closeDialog } = useDialogs();
  const localizedVisitDate = formatShortDate(date);
  const { isManager: currentRoleIsManager } = useCurrentUser();

  const onCollapse = (e) => {
    e.preventDefault();
    const value = !open;
    setOpen(value);
  };

  const onShow = (e, noteId) => {
    e.preventDefault();
    redirectToSiteVisit(siteId, noteId);
  };

  const onEdit = (e, noteId) => {
    e.preventDefault();
    redirectToSiteVisitEdit(siteId, noteId);
  };

  const handleOpenDeleteModal = (e, visitId) => {
    openDialog({
      message: 'Are you sure you want to delete this visit? This cannot be undone.',
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            onDeleteVisit(e, visitId);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  const actionItemButtons = [
    { label: 'Edit', onClick: (e) => onEdit(e, id) },
    { label: 'Delete', onClick: (e) => handleOpenDeleteModal(e, id) },
  ];
  const actionItems = accessToDeleteBtn(actionItemButtons, currentRoleIsManager);

  // Set up for when mouse hovers over visit icons, it renders tooltip
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const tooltip = {
    opacity: isHover ? '1' : '0',
  };

  return (
    <div className="card bg-light" style={{ border: 'none' }}>
      <div className="note-header d-flex bg-white">
        <div
          className="pt-3 mt-3"
          style={styles.tooltipTrigger}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >{completed
          ? <FontAwesomeIcon className="ml-4" icon={faCheck} />
          : <Icon className="ml-4" path={mdiAccountArrowRight} size={1} /> }
          <div style={{ ...tooltip, ...styles.tooltip, ...styles.tooltipPosition }}>{completed ? 'completed' : 'attempted'}
          </div>
        </div>
        <button type="button" className="card-header bg-white align-items-center btn" onClick={(e) => onCollapse(e)} style={styles.visitHeaderDiv}>
          <h5 className="mb-0">{purpose}</h5>
          <div style={{ color: '#495057' }}>
            {localizedVisitDate}
          </div>
        </button>
        <MenuButton items={actionItems} />
      </div>
      {open ? (
        <button type="button" className="card-body bg-light btn btn-light text-left" style={styles.cardBody} onClick={(e) => onShow(e, id)}>
          <div><b>Leader: </b>{leader}</div>
          <div><b>Workers reached: </b>{workersInteracted}</div>
          <div><b>Total workers: </b>{workersTotal}</div>
          <div><b>Visitors: </b>{visitors}</div>
          <div><b>Donations: </b>{donations}</div>
          <div><b>Needs/Concerns: </b>{needsConcerns}</div>
          <div><b>Other notes: </b>{otherNotes}</div>
        </button>
      ) : null}
    </div>

  );
};

SiteVisitItem.defaultProps = {
  workersInteracted: null,
  workersTotal: null,
  needsConcerns: '',
};

SiteVisitItem.propTypes = {
  leader: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  workersInteracted: PropTypes.number,
  workersTotal: PropTypes.number,
  visitors: PropTypes.string.isRequired,
  donations: PropTypes.string.isRequired,
  needsConcerns: PropTypes.string,
  otherNotes: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  siteId: PropTypes.number.isRequired,
  onDeleteVisit: PropTypes.func.isRequired,
  completed: PropTypes.string.isRequired,
};

export default SiteVisitItem;
