/**
 * This function transform properties from snake_case to camel case convenction
 * @param {*} route object to transform
 * @returns new object with properties in camel case convection
 */
export const transformRouteStopToCamelCase = (route) => {
  // Desctructure object to take out properties in snake_case convention
  const {
    id: routeId, organization_id: organizationId, route_stops: stops,
    total_distance: totalDistance, total_time: totalTime, ...rest
  } = route;

  // Create a new object with properties in camel case convention
  return {
    routeId,
    organizationId,
    stops,
    totalDistance,
    totalTime,
    ...rest,
  };
};
