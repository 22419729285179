import React from 'react';

const SeasonHeader = () => {
  return (
    <thead className="table-heading">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Start Date</th>
        <th scope="col">End Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
  );
};

export default SeasonHeader;
