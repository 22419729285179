export const styles = {
  table: {
    width: '95%',
    margin: '0 auto',
  },
  removeButton: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: 5,
  },
  tableDiv: {
    backgroundColor: '#FAFAFA',
    marginTop: 20,
    marginBottom: 20,
  },
  tableTitle: {
    color: 'black',
    paddingLeft: 20,
  },
  fieldIcon: {
    width: 20,
    float: 'left',
  },
  fieldText: {
    marginLeft: 10,
    float: 'left',
  },

  tableHeaderEmpty: {
    paddingLeft: '42px',
  },
};
