import React from 'react';

/**
 * Higher Order Component to wrap a component with a Context Provider
 * @param {*} Provider Context Provider
 * @returns New component with access to context
 */
export const withContextProvider = (Provider) => (Component) => (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Provider>
    <Component {...props} />
  </Provider>
);
