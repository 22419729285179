export const SITE_PRIORITY_HIGH = 'high';
export const SITE_PRIORITY_MEDIUM = 'medium';
export const SITE_PRIORITY_LOW = 'low';
export const SITE_PRIORITY_NO_PRIORITY = 'no_priority';

export const SITE_STATUS_ACTIVE = 'active';
export const SITE_STATUS_INACTIVE = 'inactive';
export const SITE_STATUS_REVIEW = 'review';

export const AUDIT_ACTION_CREATE = 'create';
export const AUDIT_ACTION_DESTROY = 'destroy';

export const SITE_TYPES = [
  'Apartment',
  'Barracks',
  'House',
  'Motel/Hotel',
  'Mobile Home Park',
  'Trailer/RV',
  'Non-Housing',
  'Community Location',
  'Community Partner',
  'Other',
];

export const ICONS_BASE = {
  fillOpacity: 1, strokeWeight: 0.5, scale: 1.25, anchor: { x: 12, y: 22 },
};
export const COLOR_GREY = '#7d7d7d';
export const COLOR_RED = '#FF0000';
export const COLOR_ORANGE = '#Fe7e01';
export const COLOR_GREEN = '#009546';
export const COLOR_BITTERSWEET = '#FE6256';

export const SITE_STATES = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'American Samoa', value: 'AS' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'Delaware', value: 'DE' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Federated States of Micronesia', value: 'FSM' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Guam', value: 'GU' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Marshall Islands', value: 'MH' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Northern Mariana Islands', value: 'MP' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Palau', value: 'PW' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Virgin Islands', value: 'VI' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];
