import { ADD_TOAST, REMOVE_TOAST } from '../actionTypes/toasts';

export const addToast = (toast) => {
  return {
    payload: toast,
    type: ADD_TOAST,
  };
};

export const removeToast = (id) => {
  return {
    payload: { id },
    type: REMOVE_TOAST,
  };
};
