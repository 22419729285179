import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { removeContactsFromSite } from '../../../../utils/api/site';
import PriorityFieldForSiteContactTable from '../PriorityFieldForSiteContactTable';
import { styles } from './ContactSite.component.style';
import { useDialogs } from '../../../../hooks';
import IconButton from '../../../FormInputs/IconButton';

const ContactSite = ({ sites, contact }) => {
  const [currentSites, setCurrentSites] = useState(sites);
  const formData = {
    organization_id: contact.organization_id,
    contacts: [contact],
  };
  const { openDialog, closeDialog } = useDialogs();

  const handleRemove = (formDataParam, siteId) => {
    removeContactsFromSite(formDataParam, siteId).then(
      setCurrentSites(currentSites.filter((site) => site.id !== siteId)),
    );
  };

  const handleOpenDeleteModal = (data, siteId) => {
    openDialog({
      message: 'Are you sure you want to remove this contact from this site?',
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            handleRemove(data, siteId);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  const sitesList = currentSites.map((site) => {
    // In the past, some contacts were connected to other organization's sites even though same sites were present in their organization.
    // As, it used to fetch the site which was created first. We have added the organization id check now, which will solve the association problem.
    // For previous data, the incorrect associated sites will not be shown, to avoid "Record Not Found" error.
    if (site.organization_id !== contact.organization_id) {
      return null;
    }
    return (
      <tr key={site.id}>
        <td>
          <a href={`/sites/${site.id}`}>{site.name}</a>
        </td>
        <PriorityFieldForSiteContactTable
          priority={site.priority}
          styles={styles}
        />
        <td>{site.city}</td>
        <td>{site.state}</td>
        <td>
          <IconButton
            icon={<FaTimes className="text-danger fa fa-2x" />}
            onClick={() => handleOpenDeleteModal(formData, site.id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div style={styles.tableDiv} className="table-responsive">
      <p className="table-heading" style={styles.tableTitle}>
        Related Sites
      </p>
      <table style={styles.table} className="table table-borderless">
        <thead className="border-bottom">
          <tr>
            <th>Name</th>
            <th style={styles.tableHeaderEmpty}>Priority</th>
            <th>City</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>{sitesList}</tbody>
      </table>
    </div>
  );
};

ContactSite.propTypes = {
  sites: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired,
};

export default ContactSite;
