const grid = 3;

// a function to reorder array based on given startIndex and endIndex values
export const reorder = (list, startIndex, endIndex) => {
  const itemsResult = Array.from(list);
  const [removed] = itemsResult.splice(startIndex, 1);
  itemsResult.splice(endIndex, 0, removed);

  return itemsResult;
};

// get Item Style function to set style durring dragging
export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',
  borderRadius: 15,
  // styles we need to apply on draggables
  ...draggableStyle,
});

// get list style function to set style durring dragging
export const getListStyle = () => ({
  padding: grid,
  maxHeight: 350,
  overflowY: 'auto',
});

// get Item Style function to set style during dragging in Custom Field Edit form
export const getFormItemStyle = (isDragging, draggableStyle) => {
  const conditionalStyles = isDragging
    ? {
      background: 'white',
      borderBottom: 'none',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }
    : {
      background: 'none',
      borderBottom: '1px solid #dee2e6',
      boxShadow: 'none',
    };

  return {
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '0.25rem',
    ...conditionalStyles,
    ...draggableStyle,
  };
};

// get list style function to set style during dragging in Custom Field Edit form
export const getFormListStyle = () => ({
  padding: grid,
});
