export const styles = {
  editIcon: {
    color: '#40b870',
  },
  infoLink: {
    color: '#40b870',
  },
  downIcon: {
    color: '#40b870',
  },
};
