import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PropTypes } from 'prop-types';
import SiteHistoryTable from './SiteHistoryTable';
import Pagination from '../../../../utils/pagination/PaginationComponent';
import paginationHandler from '../../../../utils/pagination/paginationHandler';
import { useToasts } from '../../../../hooks';

const SiteHistory = ({
  pagination, setPagination, siteId, organizationId,
}) => {
  const { showToast } = useToasts();
  const setCurrentPage = (number) => {
    paginationHandler({
      pageNumber: number,
      siteId,
      organizationId,
      pagination,
      setPagination,
      showToast,
    });
  };
  const setCurrentLimit = (event) => {
    paginationHandler({
      pageNumber: 1, limit: event.target.value, siteId, organizationId, pagination, setPagination, showToast,
    });
  };

  return (
    <>
      {
        pagination?.currentHistories?.length
          ? (
            <div>
              <Container>
                <Row>
                  <Col
                    className="site-update-history"
                  >
                    <Pagination
                      paginationObject={pagination}
                      setCurrentPage={setCurrentPage}
                      handleChange={setCurrentLimit}
                      label="Site Histories Per Page"
                    >Site Update History
                    </Pagination>
                  </Col>
                </Row>
              </Container>
              <SiteHistoryTable
                siteHistories={pagination.currentHistories}
              />
            </div>
          ) : <></>
      }
    </>
  );
};

SiteHistory.propTypes = {
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
};
export default SiteHistory;
