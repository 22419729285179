import moment from 'moment';

/**
 * Format date to the US date format
 * @param {Date} date date to format
 * @param {string} format template format
 * @returns {string} new date with format as string
 */
export const customDateFormatter = (date, format) => {
  const currentFormat = 'M/D/YY';
  let stringDate = '';

  if (date instanceof Date) {
    // Transform date to format: 2022-02-10T00:44:34.587Z
    stringDate = date.toISOString();
  } else if (typeof date === 'string') {
    stringDate = date;
  }
  // Replace hyphens by slash in order to interpretate date in UTC and avoid day off
  const dateFixed = new Date(stringDate.replace(/-/g, '/').replace(/T.+/, ''));
  return moment(dateFixed).format(format || currentFormat);
};

export const formatShortDate = (date) => {
  return customDateFormatter(date, 'MMM D, YYYY');
};

export const formatLongDate = (date) => {
  return customDateFormatter(date, 'MMMM D, YYYY');
};

export const formatNumericDate = (date) => {
  return customDateFormatter(date, 'M/D/YY');
};

export const formatShortDateWithTimeZone = (date) => {
  return moment(date).format('lll');
};
/**
 * Check if date is valid
 * @param {} str Date
 * @returns {boolean} true/false
 */
export const isDateValid = (str) => {
  if (!str) {
    return false;
  }
  return moment(new Date(str)).isValid();
};

/*
  Compare two dates if isSame or before
  */
export const areDatesSameOrBefore = (date1, date2) => {
  return moment(date1).isSameOrBefore(date2);
};

/**
 * Check if pair of dates are in range
 * A [ indicates inclusion of a value.
 * A ( indicates exclusion of a value.
 * @param {*} startDate start date
 * @param {*} endDate end date
 * @returns boolean
 */
export const isDateInRange = (startDate, endDate) => {
  return moment(startDate).isBetween(startDate, endDate, null, '[]');
};

/**
 * Function to convert seconds to minutes
 */
export const getTimeInHoursAndMins = (seconds) => {
  const d = Number(seconds);
  const hour = Math.floor(d / 3600);
  const minutes = Math.floor((d % 3600) / 60);
  const hourDisplay = hour > 0 ? `${hour} hr` : '';
  const minutesDisplay = minutes > 0 ? `${minutes} min` : '';
  return `${hourDisplay} ${minutesDisplay}`;
};
