import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import CustomFieldForm from '../../../../CustomField/CustomFieldForm';
import SiteFormContact from '../../SiteFormContact';
import {
  createSite,
  updateSite,
  addContactsToSite,
  removeContactsFromSite,
  h2aOptions,
  addImageToSite,
} from '../../../../../utils/api/site';
import { SITE_TYPES, SITE_STATES } from '../../../../../constants/sites';
import { redirectToSite, redirectToSiteEdit } from '../../../../../utils/navigation';
import { capitalizeFirstLetter } from '../../../../../utils/api';
import {
  SelectDropdown,
  DateSelector,
  TextInput,
  SelectIconDropdown,
} from '../../../../FormInputs';
import { isUSAZipCode } from '../../../../../utils/isUSAZipCode';
import ToggleSwitch from '../../../../ToggleSwitch';
import { DialogsProvider, ToastProvider } from '../../../../../providers';
import { withContextProvider } from '../../../../../hoc';
import { useToasts } from '../../../../../hooks';
import DialogModal from '../../../../DialogModal';
import SitePhotosTab from '../SitePhotosTab';

// Create array of site types in the form of { name: "", value: ""}
const siteTypesOptions = SITE_TYPES.map((item) => {
  return {
    name: item,
    value: item,
  };
});

// Create an array of states
const statesOptions = SITE_STATES;

// Create array of status in the form of { name: "", value: ""}
const statusOption = ['active', 'inactive', 'review'].map((item) => {
  return { name: capitalizeFirstLetter(item), value: item };
});

const SiteDetailsTab = ({
  site,
  location,
  siteContacts,
  orgContacts,
  organization,
  initialModalFormItems,
  values,
  activeTab,
  isHiddenSaveChangesModal,
  setIsHiddenSaveChangesModal,
}) => {
  const contactRef = useRef(null);

  const executeContactScroll = () => {
    return contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      shouldScroll: searchParams.get('scrollTo'),
      id: searchParams.get('id'),
      lat: searchParams.get('lat'),
      lng: searchParams.get('lng'),
    };
  }, [window.location.search]);

  useEffect(() => {
    if (queryParams.shouldScroll && queryParams.id === 'contacts') executeContactScroll();
  }, [queryParams]);

  const [initialLocation] = useState(
    location || {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      county: '',
      latitude: queryParams.lat || '',
      longitude: queryParams.lng || '',
      region: '',
      coords_set_manually: false,
    },
  );

  const [initialContacts] = useState(siteContacts || []);
  const [organizationId] = useState(site.organization_id || organization.id);
  const [alternateIds, setAlternateIds] = useState(site.alternate_ids || '');
  const [name, setName] = useState(site.name || '');
  const [alternateNames, setAlternateNames] = useState(
    site.alternate_names || '',
  );
  const [description, setDescription] = useState(site.description || '');
  const [units, setUnits] = useState(site.units || '');
  const [workerCount, setWorkerCount] = useState(site.worker_count || '');
  const [arrival, setArrival] = useState(site.arrival || '');
  const [departure, setDeparture] = useState(site.departure || '');
  const [h2a, setH2a] = useState(site.h2a === null ? '' : site.h2a);
  const [icon, setIcon] = useState(site.icon === null ? '' : site.icon);
  const [address1, setAddress1] = useState(initialLocation.address1 || '');
  const [address2, setAddress2] = useState(initialLocation.address2 || '');
  const [city, setCity] = useState(initialLocation.city || '');
  const [county, setCounty] = useState(initialLocation.county || '');
  const [state, setState] = useState(initialLocation.state || '');
  const [zipCode, setZipCode] = useState(initialLocation.zip_code || '');
  const [region, setRegion] = useState(initialLocation.region || '');
  const [latitude, setLatitude] = useState(initialLocation.latitude || '');
  const [longitude, setLongitude] = useState(initialLocation.longitude || '');
  const [coordsSetManually, setCoordsSetManually] = useState(initialLocation.coords_set_manually || false);
  const [directions, setDirections] = useState(site.directions || '');
  const [contacts, setContacts] = useState(siteContacts || []);
  const [memo, setMemo] = useState(site.memo || '');
  const [status, setStatus] = useState(site.status || 'active');
  const [siteType, setSiteType] = useState(site.site_type || '');
  const [modalFormItems] = useState(initialModalFormItems || []);
  const [customValues, setCustomValues] = useState(values || {});
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [images, setImages] = useState([]);

  // Hook Toast
  const { showToast } = useToasts();

  const siteCustomFields = Object.keys(customValues).map((key) => {
    let value = customValues[key];
    if (Array.isArray(customValues[key])) {
      value = JSON.stringify(customValues[key]);
    }
    return {
      form_item_id: parseInt(key, 10),
      value,
    };
  });

  const formData = useMemo(() => {
    return {
      organization_id: organizationId,
      site: {
        organization_id: organizationId,
        name,
        alternate_ids: alternateIds,
        alternate_names: alternateNames,
        description,
        site_type: siteType,
        units,
        worker_count: workerCount,
        arrival,
        departure,
        h2a,
        icon,
        address1,
        address2,
        city,
        county,
        state,
        zip_code: zipCode,
        region,
        latitude,
        longitude,
        directions,
        coords_set_manually: coordsSetManually,
        contacts,
        memo,
        status,
        custom_fields: siteCustomFields,
      },
    };
  }, [address1, address2, alternateIds, alternateNames, arrival, city, contacts, coordsSetManually, county, departure, description, directions, h2a, icon, latitude, longitude, memo, name, organizationId, region, siteCustomFields, siteType, state, status, units, workerCount, zipCode]);

  const initialFormData = useRef(formData);

  useEffect(() => {
    const formUpdated = formData && JSON.stringify(formData.site) !== JSON.stringify(initialFormData.current.site);

    if (formUpdated) {
      setIsHiddenSaveChangesModal(false);
    }
  }, [formData, setIsHiddenSaveChangesModal]);

  useEffect(() => {
    if (!isHiddenSaveChangesModal && activeTab === 'photos') {
      setIsShowModal(true);
    }
  }, [activeTab, isHiddenSaveChangesModal]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isHiddenSaveChangesModal && !isSaveButtonClicked) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isHiddenSaveChangesModal, isSaveButtonClicked]);

  const goBack = () => {
    window.history.back();
  };

  const handleBackButtonClick = () => {
    setIsBackButtonClicked(true);
    if (!isHiddenSaveChangesModal) {
      setIsShowModal(true);
    } else {
      goBack();
    }
  };

  const handleDiscard = () => {
    if (isBackButtonClicked) {
      setIsHiddenSaveChangesModal(true);
      setIsShowModal(false);
      goBack();
    } else {
      setIsHiddenSaveChangesModal(true);
      setIsShowModal(false);
    }
  };

  const onSubmit = (event, coordsUpdateFlag) => {
    // onSubmit function to submit changes entered in the form formating for endpoint
    event.preventDefault();

    // Validation function checks for zip code or empty coordinates; if condition is true, show toast message
    const validateFields = () => {
      if (!isUSAZipCode(zipCode) && zipCode !== '') {
        showToast({ message: 'Oops! Please review ZIP Code', type: 'error' });
        return false;
      }
      if (coordsUpdateFlag && (!latitude || !longitude)) {
        showToast({ message: 'Empty latitude or longitude values can\'t be saved manually', type: 'error' });
        return false;
      }
      return true;
    };

    const isFormValid = validateFields();
    if (!isFormValid) {
      return;
    }

    const contactsToAdd = contacts.filter(
      (contact) => !initialContacts.includes(contact),
    );
    const contactsToRemove = initialContacts.filter(
      (contact) => !contacts.includes(contact),
    );

    const updatedFormData = {
      ...formData,
      site: {
        ...formData.site,
        coords_set_manually: coordsUpdateFlag,
      },
    };

    const apiRequest = site.id ? updateSite : createSite;

    apiRequest(updatedFormData, site.id)
      .then((response) => {
        if ((activeTab === 'details' && response.status === 200 && images.length === 0) || (response.status === 201 && images.length === 0)) {
          showToast({ message: 'Site saved successfully', type: 'success' });
        }
        return response.json();
      })
      .then((data) => {
        if (!data.site) {
          throw new Error(Array.isArray(data) ? data[0] : data.error);
        }
        return data.site.id;
      })
      .then(async (siteId) => {
        if (contactsToAdd.length > 0) {
          await addContactsToSite(
            { organization_id: organizationId, contacts: contactsToAdd },
            siteId,
          );
        }
        if (contactsToRemove.length > 0) {
          await removeContactsFromSite(
            { organization_id: organizationId, contacts: contactsToRemove },
            siteId,
          );
        }
        // we cannot use async await in forEach because forEach() expects a synchronous function — it does not wait for promises
        // after each async code execution is completed

        // because we do not create new array, so we do not need to use map
        if (images.length > 0) {
          /* eslint-disable no-await-in-loop */
          // eslint-disable-next-line no-restricted-syntax
          for (const image of images) {
            await addImageToSite(image, siteId, organizationId);
          }
          showToast({ message: 'Site saved successfully', type: 'success' });
        }
        return siteId;
      })
      .then((siteId) => {
        if (activeTab === 'details') redirectToSite(siteId);
        else {
          localStorage.setItem('editTab', activeTab);
          redirectToSiteEdit(siteId);
        }
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  const deleteContact = (contactId) => {
    const dummySiteContacts = contacts.filter(
      (contact) => contact.id !== contactId,
    );
    setContacts(dummySiteContacts);
  };

  const addNewContact = (newContact) => {
    if (newContact) {
      const newContactId = parseInt(newContact, 10);
      const selectedContact = orgContacts.find(
        (contact) => contact.id === newContactId,
      );
      const siteContactIdList = contacts.map((contact) => contact.id);
      if (siteContactIdList.includes(newContactId)) {
        showToast({ message: 'Contact already exists', type: 'error' });
      } else if (selectedContact) {
        setContacts((prevContacts) => [...prevContacts, selectedContact]);
      }
    }
  };

  const toggleSwitchCoords = () => {
    setCoordsSetManually(!coordsSetManually);
  };
  const stateListNames = statesOptions.map((s) => ({
    value: s.name,
    name: s.name,
  }));

  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
  });

  const handleCloseUpdateCoordsModal = () => {
    setAlertBody({ show: false });
  };

  const updateCoordsYes = () => {
    handleCloseUpdateCoordsModal();
    toggleSwitchCoords(false);
  };

  const handleOpenUpdateCoordsModal = (event) => {
    if (isLocationChanged && coordsSetManually && site.id) {
      setAlertBody({
        message: ['Do you want to update Latitude and Longitude as well?', <br />, 'If choose Yes, "Update coordinates manually" will be disabled.'],
        show: true,
        actions: [
          {
            label: 'Yes',
            onClick: () => { updateCoordsYes(); onSubmit(event, false); },
          }, {
            label: 'No',
            variant: 'danger',
            onClick: () => { handleCloseUpdateCoordsModal(); onSubmit(event, true); },
          },
        ],
      });
    } else {
      setIsSaveButtonClicked(true);
      onSubmit(event, coordsSetManually);
    }
  };
  const handleNewImage = (img) => {
    setImages(prev => [...prev, { site_image: {}, image: img.image.url }]);
  };
  return (
    <DialogsProvider>
      <div className="form-group">
        <div className="row">
          <div className="col required">
            <TextInput
              label="Name"
              name="name"
              value={name}
              setValue={setName}
              isRequired
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <TextInput
              label="Alternate ID(s)"
              name="alternateID(s)"
              value={alternateIds}
              setValue={setAlternateIds}
            />
          </div>
          <div className="col">
            <TextInput
              label="Alternate name(s)"
              name="alternateName(s)"
              value={alternateNames}
              setValue={setAlternateNames}
            />
          </div>
          <div className="col">
            <TextInput
              label="Description"
              name="description"
              value={description}
              setValue={setDescription}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <SelectDropdown
              label="Site Type"
              value={siteType}
              options={siteTypesOptions}
              handleChange={(e) => { setSiteType(e.target.value); }}
            />
          </div>
          <div className="col">
            <TextInput
              label="Units"
              name="units"
              value={units}
              setValue={setUnits}
            />
          </div>
          <div className="col">
            <TextInput
              type="number"
              pattern="[0-9]*"
              min="0"
              label="Worker Count"
              step="1"
              name="workerCount"
              value={workerCount}
              setValue={setWorkerCount}
              onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <DateSelector
              label="Arrival"
              name="arrival"
              value={arrival}
              setValue={setArrival}
              shouldDefaultDate={false}
            />
          </div>

          <div className="col">
            <DateSelector
              label="Departure"
              name="departure"
              value={departure}
              setValue={setDeparture}
              shouldDefaultDate={false}
            />
          </div>
          <div className="col">
            <SelectDropdown
              label="H2A"
              value={h2a}
              options={h2aOptions}
              enableEmptyOption={false}
              handleChange={(e) => { setH2a(e.target.value); }}
              id="select_h2a"
            />
          </div>
          <div className="col">
            <SelectIconDropdown
              label="Icon"
              value={icon}
              handleChange={(e) => setIcon(e?.value ? e.value : null)}
              id="select_icon"
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <TextInput
              label="Address line 1"
              name="address1"
              value={address1}
              onChange={(e) => { setAddress1(e.target.value); setIsLocationChanged(true); }}
            />
          </div>
          <div className="col">
            <TextInput
              label="Address line 2"
              name="address2"
              value={address2}
              setValue={setAddress2}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <TextInput
              label="City"
              name="city"
              value={city}
              onChange={(e) => { setCity(e.target.value); setIsLocationChanged(true); }}
            />
          </div>
          <div className="col">
            <TextInput
              label="County"
              name="county"
              value={county}
              setValue={setCounty}
            />
          </div>
        </div>
      </div>

      <div className="form-group" />

      <div className="form-group">
        <div className="row">
          <div className="col required">
            <SelectDropdown
              label="State"
              value={state}
              options={stateListNames}
              handleChange={(e) => { setState(e.target.value); setIsLocationChanged(true); }}
              isRequired
              enableEmptyOption
              emptyLabel="Choose the state"
              id="select_state"
            />
          </div>
          <div className="col">
            <TextInput
              label="ZIP Code"
              name="zipCode"
              value={zipCode}
              errorMessage={
                isUSAZipCode(zipCode) || zipCode === ''
                  ? ''
                  : 'Please Enter Valid ZIP Code'
              }
              onChange={(e) => { setZipCode(e.target.value); setIsLocationChanged(true); }}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <TextInput
          label="Region"
          name="region"
          className="form-control"
          value={region}
          setValue={setRegion}
        />
      </div>

      <div className="form-group">
        <div className="mb-3">
          Update coordinates manually
          <ToggleSwitch
            onChange={toggleSwitchCoords}
            isEnabled={coordsSetManually}
          />
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="coordinates-tooltip">
                Vamos automatically adds lat/long coordinates based on the given address. To update these coordinates manually, toggle this button and enter in your own coordinates, OR drag the marker on the map.
              </Tooltip>
)}
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '5px' }} />
          </OverlayTrigger>
        </div>
        {coordsSetManually && (
          <div className="row">
            <div className="col">
              <TextInput
                label="Latitude"
                name="latitude"
                value={latitude}
                setValue={setLatitude}
              />
            </div>
            <div className="col">
              <TextInput
                label="Longitude"
                name="longitude"
                value={longitude}
                setValue={setLongitude}
              />
            </div>
          </div>
        )}
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <TextInput
              label="Directions"
              name="directions"
              value={directions}
              setValue={setDirections}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <TextInput
              label="Memo"
              name="memo"
              value={memo}
              setValue={setMemo}
            />
          </div>
          <div className="col-6">
            <SelectDropdown
              label="Status"
              value={status}
              options={statusOption}
              enableEmptyOption={false}
              handleChange={(e) => { setStatus(e.target.value); }}
              id="select_status"
            />
          </div>
        </div>
      </div>
      <div ref={contactRef} className="form-group">
        <SiteFormContact
          contacts={contacts}
          deleteContact={deleteContact}
          addNewContact={addNewContact}
          orgContacts={orgContacts}
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <CustomFieldForm
              values={customValues}
              modelFormItems={modalFormItems}
              onChangeValue={setCustomValues}
            />
          </div>
        </div>
      </div>
      {!site.id && (
      <SitePhotosTab
        site={site}
        organization={organization}
        setNewSiteImages={handleNewImage}
      />
      )}
      <div className="row">
        <div
          className="form-group col-md-1 offset-md-7 button-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minWidth: '25%',
          }}
        >
          <button
            type="button"
            className="btn btn-danger"
            onClick={(e) => { handleBackButtonClick(e); }}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleOpenUpdateCoordsModal}
          >
            Save
          </button>
        </div>
      </div>
      <Modal
        show={isShowModal}
        onHide={() => {
          setIsHiddenSaveChangesModal(true);
          setIsShowModal(false);
        }}
        animation={false}
      >
        <Modal.Body className="mb-3">
          <p>Would you like to save your changes?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={handleOpenUpdateCoordsModal}>Save</button>
          <button type="button" className="btn btn-danger" onClick={handleDiscard}>Discard</button>
        </Modal.Footer>
      </Modal>

      <DialogModal
        isVisible={alertBody.show}
        title={alertBody.title}
        message={alertBody.message}
        onClose={handleCloseUpdateCoordsModal}
        actions={alertBody.actions}
      />
    </DialogsProvider>
  );
};

SiteDetailsTab.propTypes = {
  site: PropTypes.object,
  location: PropTypes.object,
  siteContacts: PropTypes.array,
  orgContacts: PropTypes.array,
  organization: PropTypes.object,
  initialModalFormItems: PropTypes.array,
  values: PropTypes.object,
  activeTab: PropTypes.string,
  isHiddenSaveChangesModal: PropTypes.bool,
  setIsHiddenSaveChangesModal: PropTypes.func,
};

SiteDetailsTab.defaultProps = {
  site: {},
  location: undefined,
  siteContacts: [],
  orgContacts: [],
  organization: {},
  initialModalFormItems: [],
  values: {},
  activeTab: '',
  isHiddenSaveChangesModal: true,
  setIsHiddenSaveChangesModal: () => { },
};

export default withContextProvider(ToastProvider)(SiteDetailsTab);
