import React from 'react';
// import { PropTypes } from 'prop-types';
import { styles } from './HelpPage.component.style';
import FarmWorkers from '../../../../assets/images/farmworkers.png';

const {
  help, h1, h2, h3, h4, h5, p, div1, div2, resources, resourceBox, link, div3, feature, div4, contact, img, btn, pWithPadding, pBox, iframe, resourcesTitle,
} = styles;
const HelpPage = () => {
  return (
    <div style={help}>
      <div style={div1}>
        <h4 style={h4}>Vamos Help Page </h4>
        <h1 style={h1}>Need help navigating Vamos?</h1>
        <h5 style={h5}>Let us help you make the most of the app.</h5>
      </div>
      <div style={div2}>
        <h2 style={resourcesTitle}>Resources</h2>
        <div style={resources}>
          <div style={resourceBox}>
            <h3 style={h3}>Vamos User Guide</h3>
            <p style={pBox}>Everything you need to know
              to put Vamos to work.
            </p>
            <a style={link} href="https://docs.google.com/document/d/1M7dB9txljYhpIDLA7gDzMoGdD2qVJ7XZcnvUSu7GwUY/edit?usp=sharing" target="#">Visit Vamos User Guide</a>
          </div>
          <div style={resourceBox}>
            <h3 style={h3}>Data Imports Guide</h3>
            <p style={pBox}>General information about
              importing.
            </p>
            <a style={link} href="https://docs.google.com/document/d/1wJijOJokFYzAdikGchZh1rD_qg3Wb2Yibpz8Aju8Zpg/edit?usp=sharing" target="#">Visit Data Imports Guide</a>
          </div>
          <div style={resourceBox}>
            <h3 style={h3}>Vamos Training Site</h3>
            <p style={pBox}>Connect to all our Vamos
              user materials including
              training videos.
            </p>
            <a style={link} href="https://codethedream.org/vamos-training/" target="#">Visit Vamos Training Site</a>
          </div>
        </div>
      </div>
      <div style={div3}>
        <div style={feature}>
          <h4 style={h4}>Want a new feature? Find a bug?</h4>
          <h2 style={h2}>We’re here to help</h2>
          <p style={pWithPadding}>Fill out the Vamos request form to communicate with us what feature you’d
            like to see us add in the future or to report a bug.
          </p>
          <p style={p}>Your organization should also have a link to view your bug reports and the status
            of each one. If you aren’t sure how to access that link, reach out to your contact
            at Code the Dream.
          </p>
        </div>
        <iframe title="Future/Bug Form" src="https://airtable.com/embed/app8EC0NO7FrnAMlP/shrJYzImLvfS9S9Jd" style={iframe} />
      </div>
      <div style={div4}>
        <div style={contact}>
          <h4 style={h4}>Contact us</h4>
          <h2 style={h2}>Couldn’t find what you need?</h2>
          <p style={pWithPadding}>Code the Dream team is ready to assist.</p>
          <button style={btn} type="button" onClick={() => { window.location.href = 'mailto:vamos@codethedream.org'; }}>Contact Us</button>
        </div>
        <div style={img}>
          <img alt="farm workers on field" src={FarmWorkers} width="337" height="342" />
        </div>
      </div>
    </div>
  );
};

export default HelpPage;

HelpPage.propTypes = {
};

