import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../../../../utils/api/index';

const FormGroupDefault = ({ item, customValues, handleCustomFieldChange }) => {
  const label = capitalizeFirstLetter(item.label);

  return (
    <Form.Group controlId={item.key}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        placeholder={`Enter ${item.label}`}
        required={item.required}
        onChange={handleCustomFieldChange}
        name={item.key}
        value={customValues[item.key] ? customValues[item.key] : ''}
      />
    </Form.Group>
  );
};

FormGroupDefault.propTypes = {
  item: PropTypes.object.isRequired,
  customValues: PropTypes.object.isRequired,
  handleCustomFieldChange: PropTypes.func.isRequired,
};

export default FormGroupDefault;
