import React from 'react';
import { PropTypes } from 'prop-types';
import NoImage from '../../../../../../../../assets/images/noimage.jpg';

const containerStyles = {
  height: 350,
  width: '100%',
  minWidth: '100%',
};

const imgStyles = {
  objectFit: 'contain',
};

const CarouselSiteImage = ({
  image,
  imageID,
  isPrimaryImage,
  children,
}) => {
  return (
    <div
      style={containerStyles}
      className={`carousel-item  ${isPrimaryImage ? 'active' : ''}`}
    >
      <img
        src={image || NoImage}
        className="d-block w-100 h-100"
        alt={`${imageID} slide`}
        style={imgStyles}
      />
      {children}
    </div>
  );
};

CarouselSiteImage.defaultProps = {
  image: '',
  imageID: -1,
  isPrimaryImage: true,
  children: null,
};

CarouselSiteImage.propTypes = {
  image: PropTypes.string,
  imageID: PropTypes.number,
  isPrimaryImage: PropTypes.bool,
  children: PropTypes.node,
};

export default CarouselSiteImage;
