import React from 'react';
import { RoutesContext } from '../context';

/**
 * Custom hook to use functions exported by RoutesContext
 * @returns useRoutes hook
 */
export const useRoutes = () => {
  return React.useContext(RoutesContext);
};
