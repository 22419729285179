import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { capitalizeFirstLetter } from '../../../../../utils/api/index';
import { TextInput } from '../../../../FormInputs';
import { formatNumericDate } from '../../../../../utils/dates';

const DateField = ({
  item, customValues, handleCustomFieldChange,
}) => {
  const label = capitalizeFirstLetter(item.label);

  const handleChange = (date) => {
    const newDate = date === null ? null : formatNumericDate(date);
    handleCustomFieldChange({ target: { value: newDate, name: item.key } });
  };

  const selectionFormatter = (input) => {
    const type = typeof input[item.key];
    switch (type) {
      case 'string':
        return input[item.key] === '' ? null : new Date(input[item.key]);
      default:
        return input[item.key];
    }
  };

  return (
    <Form.Group controlId={item.key}>
      <DatePicker
        name={item.label}
        selected={selectionFormatter(customValues)}
        onChange={selectedDate => handleChange(selectedDate)}
        isClearable
        dateFormat="M/d/yy"
        customInput={(
          <TextInput
            name={item.label}
            label={label}
            value={customValues[item.key]}
          />
        )}
      />
    </Form.Group>
  );
};

DateField.propTypes = {
  item: PropTypes.object.isRequired,
  customValues: PropTypes.object.isRequired,
  handleCustomFieldChange: PropTypes.func.isRequired,
};

export default DateField;
