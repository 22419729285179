import React from 'react';
import { PropTypes } from 'prop-types';
import {
  faLink, faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import TableTdWithIcon from '../TableTdWithIcon';
import {
  AUDIT_ACTION_CREATE,
  AUDIT_ACTION_DESTROY,
} from '../../../../constants/sites';

const ActionFieldForAuditSiteContactTable = ({
  action,
  comment,
  styles,
}) => {
  switch (action) {
    case AUDIT_ACTION_CREATE:
      return (
        <TableTdWithIcon icon={faLink} styles={styles} iconColor="green" fieldText="Added" comment={comment} />
      );
    case AUDIT_ACTION_DESTROY:
      return (
        <TableTdWithIcon icon={faUnlink} styles={styles} iconColor="red" fieldText="Removed" />
      );
    default:
      return (
        <TableTdWithIcon icon={faLink} styles={styles} iconColor="green" fieldText="Added" />
      );
  }
};

ActionFieldForAuditSiteContactTable.propTypes = {
  action: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};

export default ActionFieldForAuditSiteContactTable;
