import React from 'react';
import Table from 'react-bootstrap/Table';
import { HiArrowLongRight } from 'react-icons/hi2';
import { PropTypes } from 'prop-types';
import startcase from 'lodash/startCase';
import { formatShortDateWithTimeZone, customDateFormatter } from '../../../../utils/dates';

const svg = {
  marginLeft: '20px',
  marginRight: '40px',
};

const formatLabels = (label) => {
  let newLabel = label;
  if (label === 'alternate_ids') {
    newLabel = ('Alternate Id(s)');
  } else if (label === 'h2a') {
    newLabel = 'H2A';
  } else {
    newLabel = startcase(label);
  }
  return newLabel;
};

const formatValue = (value, label) => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';
  if (typeof value === 'number') return value;

  if (typeof value === 'string') {
    // If the string is empty or contains only whitespace, return 'No Value'
    if (value.trim() === '') return 'No Value';

    // Regular expression to match dates in the format M/D/YY
    const dateRegex = /^([1-9]|1[012])\/([1-9]|[12][0-9]|3[01])\/\d{2}/;

    // If string value starts and ends with brackets, remove the brackets
    if (value.startsWith('[') && value.endsWith(']')) {
      return value.slice(1, -1);
    }

    // If label is 'arrival' or 'departure', or value matches a date pattern, format the date
    if (['arrival', 'departure'].includes(label) || dateRegex.test(value)) {
      return customDateFormatter(value, 'll');
    }
  }

  // If value is null or undefined, return 'No Value'
  if (!value) return 'No Value';

  // If none of the above conditions apply, return the value itself
  return value;
};

const formatChanges = (siteChanges) => {
  // Return an array of objects that have a label, formattedOldValue, formattedTrueValue
  const formattedChanges = Object.entries(siteChanges).map((change) => {
    // Offline created sites are returning history format a little different
    // The unhandled formatting differences is breaking the details page on offline created sites.
    // As a temp fix adding Nullish coalescing (??) operators  to lines  51 and  ternary operator(?) 54 Both of these should be removed once backend makes changes for a more consistent response
    const fieldLabel = change[0];
    const fieldHistory = change[1];

    const { oldValue, newValue } = fieldHistory ?? { oldValue: undefined, newValue: undefined };

    const formattedOldValue = formatValue(oldValue, fieldLabel);
    // newValue comes back as both undefined and null. We only want to use fieldHistory when newValue is undefined.
    // When removing fields (custom date field particularly) our newValue becomes null.
    const formattedNewValue = formatValue(newValue !== undefined ? newValue : fieldHistory, fieldLabel);
    return {
      label: formatLabels(change[0]),
      formattedOldValue,
      formattedNewValue,
    };
  });
  return formattedChanges;
};

const SiteHistoryTable = ({ siteHistories }) => {
  const columnElements = () => {
    return siteHistories.map((siteHistory) => {
      const formattedChanges = formatChanges(siteHistory.site_changes);
      return (
        <tr key={siteHistory.id}>
          <td>
            {formatShortDateWithTimeZone(siteHistory.updated_at)}
            <div className="date-column-username">
              <p>{siteHistory.user_name}</p>
            </div>
          </td>
          <td>
            {formattedChanges.map((change) => (
              <div className="line-of-changes">
                <h6 className="site-history-field-changed">{change.label}</h6>
                <div className="site-history-update">
                  <p>{change.formattedOldValue}</p>
                  <HiArrowLongRight style={svg} />
                  <p>{change.formattedNewValue}</p>
                </div>
              </div>
            ))}
          </td>
        </tr>
      );
    });
  };

  return (
    <Table striped bordered hover>
      <thead className="site-history-table-head">
        <tr>
          <th className="site-history-table-date-column-header">Date</th>
          <th className="site-history-changes-column-header">Changes</th>
        </tr>
      </thead>
      <tbody>
        {columnElements()}
      </tbody>
    </Table>
  );
};

SiteHistoryTable.propTypes = {
  siteHistories: PropTypes.array.isRequired,
};
export default SiteHistoryTable;
