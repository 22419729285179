import React from 'react';
import { PropTypes } from 'prop-types';
import * as CustomFieldShowItems from './CustomFieldShowItem';
import * as customFieldTypes from '../../../constants/customFields';

const CustomFieldShow = ({ values, modelFormItems, styles }) => {
  const formItems = modelFormItems.map(item => {
    switch (item.type) {
      case customFieldTypes.FIELD_TYPE_CHECKBOX_GROUP:
        return (
          <CustomFieldShowItems.MultiSelect
            item={item}
            values={values}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_TEXT_AREA:
        return (
          <CustomFieldShowItems.TextArea
            item={item}
            values={values}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_TEXT:
        return (
          <CustomFieldShowItems.Line
            item={item}
            values={values}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_NUMBER:
        return (
          <CustomFieldShowItems.Number
            item={item}
            values={values}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_DATE:
        return (
          <CustomFieldShowItems.Date
            item={item}
            values={values}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_HEADER:
        return (
          <CustomFieldShowItems.Header
            item={item}
            styles={styles}
          />
        );
      case customFieldTypes.FIELD_TYPE_SELECT:
        return (
          <CustomFieldShowItems.Select
            item={item}
            values={values}
            styles={styles}
          />
        );
      default:
        return (
          <CustomFieldShowItems.Line
            item={item}
            values={values}
            styles={styles}
          />
        );
    }
  });
  return (
    <div style={styles.table}>
      {formItems.length ? <h4 className="mt-2 pb-1 border-bottom">Custom Fields</h4> : null}
      <table>
        <tbody>
          {formItems}
        </tbody>
      </table>
    </div>
  );
};

CustomFieldShow.propTypes = {
  values: PropTypes.object.isRequired,
  modelFormItems: PropTypes.array.isRequired,
  styles: PropTypes.object,
};

CustomFieldShow.defaultProps = {
  styles: { thClassName: { className: ' ' } },
};

export default CustomFieldShow;
