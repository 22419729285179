export const styles = {
  noteItemCard: {
    border: 'none',
  },
  noteDate: {
    color: '#495057',
  },
  noteEdit: {
    color: '#40b870',
    cursor: 'pointer',
  },
  noteDelete: {
    color: '#6c757d',
    cursor: 'pointer',
  },
  cardBody: {
    cursor: 'pointer',
  },
};
