import React from 'react';
import PropTypes from 'prop-types';
import { CurrentUserContext } from '../context';

export const CurrentUserProvider = ({ children, values }) => {
  return (
    <CurrentUserContext.Provider value={values}>
      {children}
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
  values: PropTypes.shape({
    isManager: PropTypes.bool,
  }).isRequired,
};
