import { ACTION_UPDATE_CURRENT_ROUTE } from '../actionTypes/routes';

export const routesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_UPDATE_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
