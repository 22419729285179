import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { IconButton } from '../FormInputs';

export const ToggleButton = ({ label, eventKey, ...props }) => {
  const currentEventKey = useContext(AccordionContext);

  const handleToggle = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="ml-2" {...props}>
      <IconButton
        label={label}
        icon={isCurrentEventKey ? <FaCaretUp /> : <FaCaretDown />}
        onClick={handleToggle}
      />
    </div>
  );
};

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
};
