import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { PhotoView } from 'react-photo-view';
import MenuButton from '../../../../FormInputs/MenuButton';
import { ICON_COLOR_WHITE, SMALL_ICON_SIZE } from '../../../../../constants/theme';

const Card = (props) => {
  const ref = useRef(null);
  const {
    getActionItems,
    siteImage,
    activeImage,
    setActiveImage,
    targetIndex,
    setTargetIndex,
    setHoverIndex,
    updateImagePosition,
    index,
    hoverIndex,
    shouldHaveLineOnRight,
    shouldHaveLineOnLeft,
    siteId,
  } = props;

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => {
      setActiveImage(siteImage);
      return { id: siteImage.id, url: siteImage.image.url };
    },
    end: () => {
      updateImagePosition(activeImage.id, targetIndex > activeImage.position ? targetIndex - 1 : targetIndex);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: 'card',
    hover: (_, monitor) => {
      const hoverIndexLocal = index;
      if (hoverIndexLocal !== hoverIndex) {
        setHoverIndex(hoverIndexLocal);
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const midX = hoverBoundingRect.left
        + (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const pointerOffset = monitor.getClientOffset();
      const newInsertIndex = pointerOffset.x < midX ? hoverIndexLocal : hoverIndexLocal + 1;
      setTargetIndex(newInsertIndex);
    },
    collect: (monitor) => ({
      hovered: monitor.isOver(),
    }),
  });

  drag(drop(ref));

  // creates white frame around dragged card prview
  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div className="col-md-4 mb-4 position-relative">
      {shouldHaveLineOnLeft && hovered && (
        <div className="insert-line-left" />
      )}

      <div
        style={{
          opacity,
          height: '225px',
          outline: index === 0 ? '4px solid #018544' : 'none',
        }}
        ref={ref}
      >
        <PhotoView src={siteImage.image.url}>
          <img
            src={siteImage.image.url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            alt=""
          />
        </PhotoView>
        {index === 0 && <div className="cover-photo-label"><div>Cover photo</div></div>}
        <div className="menu-btn-gallery">
          <MenuButton items={getActionItems(siteImage.id)} iconColor={ICON_COLOR_WHITE} iconSize={SMALL_ICON_SIZE} isNewSite={!siteId} />
        </div>
      </div>
      {shouldHaveLineOnRight && hovered && (
        <div className="insert-line-right" />
      )}
    </div>
  );
};

Card.defaultProps = {
  activeImage: null,
  targetIndex: -1,
  index: null,
  hoverIndex: -1,
  shouldHaveLineOnRight: false,
  shouldHaveLineOnLeft: false,
  siteId: null,
};

Card.propTypes = {
  getActionItems: PropTypes.func.isRequired,
  siteImage: PropTypes.object.isRequired,
  activeImage: PropTypes.object,
  setActiveImage: PropTypes.func.isRequired,
  targetIndex: PropTypes.number,
  setTargetIndex: PropTypes.func.isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  updateImagePosition: PropTypes.func.isRequired,
  index: PropTypes.number,
  hoverIndex: PropTypes.number,
  shouldHaveLineOnRight: PropTypes.bool,
  shouldHaveLineOnLeft: PropTypes.bool,
  siteId: PropTypes.number,
};

export default Card;
