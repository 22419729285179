import React from 'react';
import { PropTypes } from 'prop-types';

const ToggleSwitch = ({ label, isEnabled, onChange }) => {
  return (
    <label className="toggle-switch">
      {label}
      <input id="toggleSwitch" type="checkbox" checked={isEnabled} onChange={onChange} />
      <span className="switch" />
    </label>
  );
};

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func,
};
ToggleSwitch.defaultProps = {
  label: '',
  isEnabled: false,
  onChange: undefined,
};

export default ToggleSwitch;
