import React from 'react';
import Table from 'react-bootstrap/Table';
import { PropTypes } from 'prop-types';
import { formatShortDateWithTimeZone } from '../../../../../utils/dates';
import { getContact } from '../../../../../utils/api/contact';
import { useToasts } from '../../../../../hooks';
import { redirectToContact } from '../../../../../utils/navigation';

const ContactHistoryTable = ({ ContactHistories, organizationId }) => {
  const { showToast } = useToasts();
  const checkContact = (id) => {
    getContact(organizationId, id)
      .then((response) => {
        if (response.status === 200) {
          redirectToContact(id);
        } else if (response.status === 404) {
          showToast({ message: 'This contact has been deleted', type: 'error' });
        }
      })
      .catch((error) => {
        showToast({ message: error, type: 'error' });
      });
  };
  const columnElements = () => {
    return ContactHistories.map((contactHistory) => {
      return (
        <tr key={contactHistory.id}>
          <td>
            {formatShortDateWithTimeZone(contactHistory.created_at)}
            <div className="date-column-username">
              <p>{contactHistory.user_name}</p>
            </div>
          </td>
          <td>
            <div className="line-of-changes">
              <div className="site-history-update">
                <h6 className="contact-action">{contactHistory.action === 'create' ? 'Added:' : 'Removed:'}</h6>
                <button
                  className="contact-button-link"
                  type="button"
                  onClick={() => checkContact(contactHistory.contact_changes.contact_id)}
                >{contactHistory.contact_changes.contact_name}
                </button>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Table striped bordered hover>
      <thead className="site-history-table-head">
        <tr>
          <th className="site-history-table-date-column-header">Date</th>
          <th className="site-history-changes-column-header">Changes</th>
        </tr>
      </thead>
      <tbody>
        {columnElements()}
      </tbody>
    </Table>
  );
};

ContactHistoryTable.propTypes = {
  ContactHistories: PropTypes.array.isRequired,
  organizationId: PropTypes.number.isRequired,
};
export default ContactHistoryTable;
