export const styles = {
  visitHeaderDiv: {
    cursor: 'pointer',
  },
  cardItem: {
    color: '#40b870',
    cursor: 'pointer',
  },
  deleteVisit: {
    color: '#6c757d',
    cursor: 'pointer',
  },
  cardBody: {
    cursor: 'pointer',
  },
  tooltipTrigger: {
    position: 'relative',
    display: 'inline',
  },
  tooltip: {
    width: '100%',
    backgroundColor: '#018544',
    color: '#ffffff',
    textAlign: 'center',
    padding: '3px 6px',
    borderRadius: '0.25rem',
    border: '1px solid #ffffff',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'relative',
    zIndex: '1',
  },
  tooltipPosition: {
    bottom: '105%',
    left: '5%',
  },
};
