import React from 'react';
import { PropTypes } from 'prop-types';
import {
  faChevronUp, faChevronDown, faRulerHorizontal, faCircle,
} from '@fortawesome/free-solid-svg-icons';
import TableTdWithIcon from '../TableTdWithIcon';
import {
  SITE_PRIORITY_HIGH,
  SITE_PRIORITY_MEDIUM,
  SITE_PRIORITY_LOW,
  SITE_PRIORITY_NO_PRIORITY,
} from '../../../../constants/sites';

const PriorityFieldForSiteContactTable = ({
  priority,
  styles,
}) => {
  switch (priority) {
    case SITE_PRIORITY_HIGH:
      return (
        <TableTdWithIcon icon={faChevronUp} styles={styles} iconColor="red" fieldText="High" />
      );
    case SITE_PRIORITY_MEDIUM:
      return (
        <TableTdWithIcon icon={faRulerHorizontal} styles={styles} iconColor="yellow" fieldText="Medium" />
      );
    case SITE_PRIORITY_LOW:
      return (
        <TableTdWithIcon icon={faChevronDown} styles={styles} iconColor="green" fieldText="Low" />
      );
    case SITE_PRIORITY_NO_PRIORITY:
      return (
        <TableTdWithIcon icon={faCircle} styles={styles} iconColor="grey" fieldText="No Priority" />
      );
    default:
      return (
        <TableTdWithIcon icon={faCircle} styles={styles} iconColor="grey" fieldText="No Priority" />
      );
  }
};

PriorityFieldForSiteContactTable.propTypes = {
  priority: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};

export default PriorityFieldForSiteContactTable;
