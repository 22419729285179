import React from 'react';
import { PropTypes } from 'prop-types';
import Linkify from 'react-linkify';

const SiteField = ({ name, attribute }) => {
  return (
    <div><b>{name}: </b><Linkify>{attribute || 'No Info'}</Linkify></div>
  );
};

SiteField.propTypes = {
  name: PropTypes.string,
  attribute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

SiteField.defaultProps = {
  name: '',
  attribute: null,
};

export default SiteField;
