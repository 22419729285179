import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VamosMapContext } from '../context';

export const VamosMapProvider = ({ children }) => {
  // State to handle selected marker
  const [selectedMarker, setSelectedMarker] = useState();

  const values = React.useMemo(() => ({
    selectedMarker,
    setSelectedMarker,
  }), [selectedMarker, setSelectedMarker]);

  return (
    <VamosMapContext.Provider value={values}>
      {children}
    </VamosMapContext.Provider>
  );
};

VamosMapProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

