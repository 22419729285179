function paginationUpdate(type, data, setPagination) {
  if (type === 'next') {
    setPagination(prevState => ({
      ...prevState,
      currentPage: data.current_page,
      firstIndex: data.first_index,
      lastIndex: data.last_index,
      currentHistories: data.histories,
    }));
  } if (type === 'fetch') {
    setPagination(prevState => ({
      ...prevState,
      perPage: data.limit,
      firstIndex: data.offset,
      lastIndex: data.limit,
      currentHistories: data.paginated_histories,
      totalPages: data.total_pages,
      totalItems: data.total_count,
    }));
  } if (type === 'limit') {
    setPagination(prevState => ({
      ...prevState,
      currentPage: data.current_page,
      perPage: data.last_index,
      firstIndex: data.first_index,
      lastIndex: data.last_index,
      currentHistories: data.histories,
      totalItems: data.total_count,
      totalPages: data.total_pages,
    }));
  }
}

export default paginationUpdate;
