import React from 'react';
import PropTypes from 'prop-types';
import { Button, Overlay, Popover as BoostrapPopover } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const Popover = React.forwardRef(({ shouldShow, content }, ref) => {
  return (
    <div ref={ref}>
      <Overlay
        show={shouldShow}
        target={ref.current}
        placement="bottom"
        containerPadding={20}
      >
        <BoostrapPopover id="popover-container">
          {content.title && <BoostrapPopover.Title as="h3" className="mb-2">{content.title}</BoostrapPopover.Title>}
          <BoostrapPopover.Content>
            {content.message}
            {content.actions && content.actions.length && (
            <div className="modal-footer border-0">
              {content.actions.map((item) => (
                <Button
                  key={uuidv4()}
                  variant={item.variant || 'success'}
                  className={item.className ? item.className : ''}
                  onClick={item.onClick && item.onClick}
                >
                  {item.label}
                </Button>
              ))}
            </div>
            )}

          </BoostrapPopover.Content>
        </BoostrapPopover>
      </Overlay>
    </div>
  );
});

Popover.defaultProps = {
  shouldShow: false,
};

Popover.propTypes = {
  shouldShow: PropTypes.bool,
  content: PropTypes.object.isRequired,
};

export default Popover;
