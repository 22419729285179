import React from 'react';
import { PropTypes } from 'prop-types';
import FormGroupDefault from '../../FormGroupComponents/FormGroupDefault';

const Line = ({ item, customValues, handleCustomFieldChange }) => {
  return (
    <FormGroupDefault
      item={item}
      customValues={customValues}
      handleCustomFieldChange={handleCustomFieldChange}
    />
  );
};

Line.propTypes = {
  item: PropTypes.object.isRequired,
  customValues: PropTypes.object.isRequired,
  handleCustomFieldChange: PropTypes.func.isRequired,
};

export default Line;
