import React from 'react';
import { PropTypes } from 'prop-types';
import TrForMultiSelect from '../TableComponents/TrForMultiSelect';

const MultiSelect = ({ item, values, styles }) => {
  return (
    <TrForMultiSelect item={item} values={values} styles={styles} />
  );
};

MultiSelect.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default MultiSelect;
