import React, {
  useCallback, useState,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { DialogContext } from '../context/DialogContext';

export const DialogsProvider = ({ children }) => {
  // State to open/close route modal
  const [openDialogShow, setOpenDialogShow] = useState(false);

  // State to handle custom dialog
  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    actions: [],
  });

  const openDialog = useCallback((dialog) => {
    setAlertBody(dialog);
    setOpenDialogShow(true);
  }, []);

  const closeDialog = useCallback(() => {
    setAlertBody({});
    setOpenDialogShow(false);
  }, []);
  /**
   * Close modal
   */
  const handleClose = () => {
    // If callback available, runs callback
    if (alertBody.onClose) {
      alertBody.onClose();
    }
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <Modal show={openDialogShow} onHide={handleClose} animation={false}>
        { alertBody.title && (
        <Modal.Header closeButton>
          <h5 className="text-center">{alertBody.title}</h5>
        </Modal.Header>
        )}
        <div className="modal-body m-0">
          {alertBody.message && <p>{alertBody.message}</p>}
        </div>
        <div className="modal-footer m-0">
          {alertBody.actions && alertBody.actions.length ? alertBody.actions.map((item) => (
            <Button
              key={uuidv4()}
              variant={item.variant || 'success'}
              className={item.className ? item.className : ''}
              onClick={item.onClick && item.onClick}
            >
              {item.label}
            </Button>
          ))
            : (
              <Button onClick={handleClose} variant="success">
                {alertBody.buttonLabel}
              </Button>
            )}
        </div>
      </Modal>

    </DialogContext.Provider>
  );
};

DialogsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
