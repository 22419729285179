import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Icon from '@mdi/react';
import _ from 'lodash';
import { FaImage, FaMap } from 'react-icons/fa';
import IconButton from '../../FormInputs/IconButton';
import { ICON_COLOR_GRAY, MEDIUM_ICON_SIZE } from '../../../constants/theme';
import SiteInfo from './SiteInfo';
import SiteTags from './SiteTags';
import SiteContacts from './SiteContacts';
import SiteNotes from './SiteNotes';
import SiteVisits from './SiteVisits';
import SiteHistory from './SiteHistories';
import { getSiteInfo, getIcon } from '../../../utils/api/site';
import { DialogsProvider } from '../../../providers/DialogProvider';
import { ToastProvider } from '../../../providers';
import { withContextProvider } from '../../../hoc';
import { useToasts } from '../../../hooks';
import SiteMap from '../SiteMap';
import SiteImage from './SiteView/SiteImage';
import { CurrentUserProvider } from '../../../providers/CurrentUserProvider';
import { INITIAL_PAGINATION } from '../../../constants/pagination';
import ContactHistory from './SiteHistories/ContactHistories';
import paginationUpdate from '../../../utils/pagination/paginationUpdate';

const SiteShow = ({
  siteId,
  organizationId,
  organizationTags,
  values,
  modelFormItems,
  isManager,
}) => {
  // Hook Toast
  const { showToast } = useToasts();
  const [siteData, setSiteData] = useState({});
  const [imageView, setImageView] = useState(true);
  const [images, setImages] = useState([]);
  const [siteWithLastVisit, setSiteWithLastVisit] = useState(siteData);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  useEffect(() => {
    setImages(siteData.site_images || []);
    /* Because we need last visit value in Site Info Window,
    in here we are getting this information from site.visits
    and adding new field to site object to be used */
    const orderedVisit = _.orderBy(siteData.visits, ['date'], ['desc']);
    const lastVisitDate = orderedVisit.length ? orderedVisit[0].date : null;
    setSiteWithLastVisit({ ...siteData, last_visit_date: lastVisitDate });
  }, [siteData]);

  useEffect(() => {
    getSiteInfo(siteId, organizationId)
      .then((response) => response.json())
      .then(data => {
        if (!data.site) {
          throw new Error(data.error);
        }
        if (data.site.site_histories) {
          paginationUpdate('fetch', data.site.site_histories, setPagination);
        }
        setSiteData(data.site);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  }, [siteId, organizationId, showToast]);

  const showImage = () => {
    setImageView(true);
  };

  const showMap = () => {
    setImageView(false);
  };

  return (
    <CurrentUserProvider values={{ isManager }}>
      <DialogsProvider>
        <div className="site-div">
          <div className="row">
            <div className="col-sm mb-3">
              <div className="row justify-content-start pt-4 ">
                {siteData.icon && <Icon className="ml-3" path={getIcon(siteData.icon)} size={1.5} />}
                <h2 className="col-9 text-left"> {siteData.name} </h2>
              </div>
              <div className="d-flex justify-content-end pb-2">
                <IconButton
                  className={`btn ml-2 ${imageView ? 'focus' : ''} `}
                  icon={<FaImage color={ICON_COLOR_GRAY} size={MEDIUM_ICON_SIZE} />}
                  onClick={showImage}
                />
                <IconButton
                  className={`btn ml-2 ${!imageView ? 'focus' : ''} `}
                  icon={<FaMap color={ICON_COLOR_GRAY} size={MEDIUM_ICON_SIZE} />}
                  onClick={showMap}
                />
              </div>
              <div className="row">
                <div className="container">
                  {imageView
                    ? <SiteImage siteImages={images} setSiteImages={setImages} siteId={siteData.id} organizationId={siteData.organization_id} />
                    : <SiteMap site={siteWithLastVisit} setSiteData={setSiteData} icon={siteData.icon} />}
                </div>
              </div>

              {
                siteData.id ? (
                  <SiteTags
                    site={siteData}
                    organizationTags={organizationTags}
                  />
                ) : null
              }

            </div>
            <div className="site-info-div col-sm">
              <SiteInfo
                site={siteData}
                values={values}
                modelFormItems={modelFormItems}
                organizationId={organizationId}
                setSiteData={setSiteData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm mb-3">
              <SiteContacts
                siteData={siteData}
                setSiteData={setSiteData}
              />
              <SiteNotes
                site={siteData}
              />
            </div>
            <div className="col-sm mb-3">
              <SiteVisits
                site={siteData}
              />
            </div>
          </div>
          <SiteHistory
            pagination={pagination}
            setPagination={setPagination}
            siteId={siteId}
            organizationId={organizationId}
          />
          <ContactHistory
            siteData={siteData}
            siteId={siteId}
            organizationId={organizationId}
          />

        </div>
      </DialogsProvider>
    </CurrentUserProvider>
  );
};

SiteShow.propTypes = {
  siteId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  organizationTags: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  modelFormItems: PropTypes.array.isRequired,
  isManager: PropTypes.bool.isRequired,
};

export default withContextProvider(ToastProvider)(SiteShow);
