import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

const DraggableItem = ({
  item, isDragDisabled, getItemStyle, children,
}) => {
  return (
    <Draggable key={item.id ? item.id : item.key} draggableId={String(item.id ? item.id : item.key)} isDragDisabled={isDragDisabled} index={item.position}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

DraggableItem.propTypes = {
  item: PropTypes.object.isRequired,
  isDragDisabled: PropTypes.bool.isRequired,
  getItemStyle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DraggableItem;
