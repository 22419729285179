import React from 'react';
import { RoutesProvider } from '../providers';

/**
 * Higher Order Component to wrap a component with a Routes Context Provider
 * @param {*} Provider Routes Context Provider
 * @returns New component with access to Route context
 */
export const withRoutesContext = (Component) => (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RoutesProvider {...props}>
    <Component />
  </RoutesProvider>
);
