import React from 'react';
import { PropTypes } from 'prop-types';
import {
  mdiHumanMaleChild,
  mdiPassport,
  mdiSnowflakeVariant,
  mdiCalendarRange,
  mdiCow,
  mdiTractorVariant,
  mdiHospitalBox,
  mdiChurch,
  mdiCart,
  mdiHandshake,
  mdiSilverwareForkKnife,
  mdiBookOpenBlankVariant,
  mdiCircle,
  mdiCheckBold,
  mdiSquare,
  mdiStar,
  mdiTriangle,
  mdiAlphaXCircle,
} from '@mdi/js';
import Select from 'react-select';
import IconOption from '../IconOption';

/**
 * Select dropdown component to render multiple options with a single selection.
 * This component render an array of object in form of { name:"", value: "", label: "", icon: "" }.
 * Property `name` must be an string and property `value` can be string, number or object
 */

const SelectIconDropdown = ({
  label, value, handleChange, isRequired, id,
}) => {
  const options = [
    {
      name: 'Family', value: 'Family', label: 'Family', icon: mdiHumanMaleChild,
    },
    {
      name: 'H2A', value: 'H2A', label: 'H2A', icon: mdiPassport,
    },
    {
      name: 'Nonseasonal', value: 'Nonseasonal', label: 'Nonseasonal', icon: mdiSnowflakeVariant,
    },
    {
      name: 'Seasonal', value: 'Seasonal', label: 'Seasonal', icon: mdiCalendarRange,
    },
    {
      name: 'Dairy', value: 'Dairy', label: 'Dairy', icon: mdiCow,
    },
    {
      name: 'Worksite', value: 'Worksite', label: 'Worksite', icon: mdiTractorVariant,
    },
    {
      name: 'Clinic', value: 'Clinic', label: 'Clinic', icon: mdiHospitalBox,
    },
    {
      name: 'Church', value: 'Church', label: 'Church', icon: mdiChurch,
    },
    {
      name: 'Grocery Store', value: 'Grocery Store', label: 'Grocery Store', icon: mdiCart,
    },
    {
      name: 'Partner Org', value: 'Partner Organization', label: 'Partner Org', icon: mdiHandshake,
    },
    {
      name: 'Restaurant', value: 'Restaurant', label: 'Restaurant', icon: mdiSilverwareForkKnife,
    },
    {
      name: 'School / Daycare', value: 'School/Daycare', label: 'School / Daycare', icon: mdiBookOpenBlankVariant,
    },
    {
      name: 'Circle', value: 'Circle', label: 'Circle', icon: mdiCircle,
    },
    {
      name: 'Check Mark', value: 'Check Mark', label: 'Check Mark', icon: mdiCheckBold,
    },
    {
      name: 'Square', value: 'Square', label: 'Square', icon: mdiSquare,
    },
    {
      name: 'Star', value: 'Star', label: 'Star', icon: mdiStar,
    },
    {
      name: 'Triangle', value: 'Triangle', label: 'Triangle', icon: mdiTriangle,
    },
    {
      name: 'X', value: 'X', label: 'X', icon: mdiAlphaXCircle,
    },
  ];

  const customStyles = {
    control: base => ({
      ...base,
      height: 38,
    }),
  };

  const findOption = (iconValue) => {
    return options.filter((o) => o.value === iconValue);
  };

  return (
    <div>
      {label && (
        <div className={`text-capitalize m-0 ${isRequired ? 'required' : ''}`}>
          {label}
        </div>
      )}
      <Select
        defaultValue={findOption(value)}
        options={options}
        onChange={handleChange}
        id={id}
        isClearable
        components={{ Option: IconOption }}
        styles={customStyles}
      />
    </div>
  );
};

SelectIconDropdown.defaultProps = {
  label: undefined,
  value: undefined,
  isRequired: false,
  id: undefined,
};

SelectIconDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default SelectIconDropdown;
