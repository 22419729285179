export const styles = {
  infoBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    position: 'relative',
  },
  infoText: {
    fontSize: 16,
    fontColor: '#08233B',
    alignSelf: 'flex-start',
    margin: 5,
  },
  button: {
    color: '#fff',
    backgroundColor: '#018544',
    borderColor: '#018544',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 5,
    background: 'none',
    border: 'none',
  },
};
