import { ACTION_UPDATE_CURRENT_ROUTE } from '../actionTypes/routes';

/**
  * Update current route stops
 * @param {*} routeStops
 */
export const updateCurrentRoute = (route) => {
  return {
    type: ACTION_UPDATE_CURRENT_ROUTE,
    payload: route,
  };
};
