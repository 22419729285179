import React from 'react';
import { DialogContext } from '../context';

/**
 * Custom hook to use functions exported by Dilalog Context
 * @returns useRoutes hook
 */
export const useDialogs = () => {
  return React.useContext(DialogContext);
};
