import React from 'react';
import PropTypes from 'prop-types';

const CardsDragPreview = ({ card }) => {
  return (
    <div>
      <div
        className="card card-dragged"
        style={{
          zIndex: 1,
        }}
      >
        <div className="card-outer">
          <div className="card-inner">
            <img src={card.url} draggable="false" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

CardsDragPreview.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardsDragPreview;
