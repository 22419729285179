import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import DatePicker from 'react-datepicker';
import { formatNumericDate, isDateValid } from '../../../utils/dates';
import TextInput from '../TextInput';

const DateSelector = ({
  label,
  name,
  value,
  setValue,
  shouldDefaultDate,
  ...props
}) => {
  // State to handle local date if @value invalid or undefined
  const [localDate, setLocalDate] = useState('');

  // Effect used to clean the DateInput
  // when value received is a invalid date
  useEffect(() => {
    if (!isDateValid(value)) {
      setLocalDate('');
      setValue('');
    } else {
      const dateFormatted = formatNumericDate(value);
      setLocalDate(new Date(dateFormatted));
    }
  }, [value, setValue]);

  useEffect(() => {
    // By default, if value is empty or undefined, the component will set
    // the current date as value. However if shouldDefaultDate is false
    // the TextInput will be empty
    if (shouldDefaultDate) {
      // Check if date provided is valid
      if (!isDateValid(value)) {
        setLocalDate(new Date());
        setValue(new Date());
      }
    }
  }, [value, setValue, shouldDefaultDate]);

  /**
   * Set date for picker and date callback
   * @param {*} newDate date selected
   */
  const onChangedDate = useCallback((newDate) => {
    if (newDate) {
      const dateFormatted = formatNumericDate(newDate);
      setLocalDate(newDate);
      // Set date in callback
      setValue(dateFormatted);
    } else {
      setLocalDate('');
      setValue('');
    }
  }, [setValue]);
  return (

    <DatePicker
      name={name}
      selected={localDate}
      onChange={onChangedDate}
      isClearable={!!value && !shouldDefaultDate}
      dateFormat="M/d/yy"
      customInput={(
        <TextInput
          name={name}
          label={label}
          value={localDate}
          {...props}
        />
    )}
    />

  );
};

DateSelector.defaultProps = {
  label: undefined,
  value: '',
  shouldDefaultDate: true,
  onKeyEnterPressed: undefined,
};

DateSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  setValue: PropTypes.func.isRequired,
  shouldDefaultDate: PropTypes.bool,
  onKeyEnterPressed: PropTypes.func,
};
export default DateSelector;
