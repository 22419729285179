import {
  mdiMapMarker,
  mdiMapMarkerRemoveVariant,
  mdiMapMarkerQuestion,
} from '@mdi/js';
import { iconsList } from '../../constants/iconsList';
import {
  post, put, destroy, get, patch,
} from './index';
import {
  SITE_PRIORITY_HIGH,
  SITE_PRIORITY_MEDIUM,
  SITE_PRIORITY_LOW,
  SITE_PRIORITY_NO_PRIORITY,
  SITE_STATUS_ACTIVE,
  SITE_STATUS_INACTIVE,
  SITE_STATUS_REVIEW,
  ICONS_BASE,
  COLOR_GREEN,
  COLOR_GREY,
  COLOR_RED,
  COLOR_ORANGE,
} from '../../constants/sites';

export const createSite = (formData) => post('/api/v1/sites', formData);
export const updateSite = (formData, siteId) => put(`/api/v1/sites/${siteId}`, formData);
export const getSiteInfo = (siteId, orgId) => get(`/api/v1/sites/${siteId}?organization_id=${orgId}`);
export const getSiteHistory = (siteId, orgId, pageNumber, limit) => get(`/api/v1/sites/${siteId}/site_history?organization_id=${orgId}&page=${pageNumber}&limit=${limit}`);
export const removeSite = (formData, siteId) => destroy(`/api/v1/sites/${siteId}`, formData);
// site contacts
export const addContactsToSite = (formData, siteId) => post(`/api/v1/sites/${siteId}/contacts`, formData);
export const removeContactsFromSite = (formData, siteId) => destroy(`/api/v1/sites/${siteId}/contacts`, formData);
export const getContactHistory = (siteId, orgId, pageNumber, limit) => get(`/api/v1/sites/${siteId}/contact_history?organization_id=${orgId}&page=${pageNumber}&limit=${limit}`);
// site images
export const addImageToSite = (formData, siteId, orgId) => post(`/api/v1/sites/${siteId}/images?organization_id=${orgId}`, formData);
// update image
export const updateImage = (orgId, siteId, imageId, newPosition) => patch(
  `/api/v2/sites/${siteId}/images/${imageId}`,
  { organization_id: orgId, new_position: newPosition },
);

// site notes
export const removeNote = (formData, noteId) => destroy(`/api/v1/notes/${noteId}`, formData);
// site visits
export const removeVisit = (formData, visitId) => destroy(`/api/v1/visits/${visitId}`, formData);
// site tags
export const removeTag = (tagId, siteId, orgId) => destroy(`/api/v3/tags/${tagId}/sites/${siteId}?organization_id=${orgId}`);
// site images
export const setPrimaryImage = (formData, siteId, id) => put(`/api/v1/sites/${siteId}/images/${id}`, formData);
export const removeImageFromSite = (siteId, id, orgId) => destroy(`/api/v1/sites/${siteId}/images/${id}?organization_id=${orgId}`);
// custom fields
export const updateCustomField = (formId, requestData) => put(`/api/v1/custom_fields/${formId}`, requestData);
export const removeCustomField = (itemId, formItemId) => destroy(`/api/v1/custom_fields/${itemId}`, formItemId);

/**
 * Fetch all sites available in Vamos
 * @param {number} orgId organization id
 * @returns Promise with HTTP response regarding all sites
 */
export const getAllSites = (orgId) => get(`/api/v1/sites?organization_id=${orgId}`);

/**
 * Returns map marker color based on provided priority params.
*/
export const getPriorityColor = (priority) => {
  switch (priority) {
    case SITE_PRIORITY_HIGH:
      return COLOR_RED;
    case SITE_PRIORITY_MEDIUM:
      return COLOR_ORANGE;
    case SITE_PRIORITY_LOW:
      return COLOR_GREEN;
    case SITE_PRIORITY_NO_PRIORITY:
      return COLOR_GREY;
    default:
      return COLOR_GREY;
  }
};

/**
 * Returns map marker path based on provided status params.
*/
const generateMapMarker = (status) => {
  switch (status) {
    case SITE_STATUS_ACTIVE:
      return mdiMapMarker;
    case SITE_STATUS_INACTIVE:
      return mdiMapMarkerRemoveVariant;
    case SITE_STATUS_REVIEW:
      return mdiMapMarkerQuestion;
    default:
      return mdiMapMarker;
  }
};

/**
 * Returns the mdi path that correspond to selected icon if any.
*/
export const getIcon = (icon) => {
  const iconObj = iconsList.filter((o) => o.value === icon);
  return iconObj.length > 0 ? iconObj[0].path : mdiMapMarker;
};

/**
 * Returns map marker based on provided priority, position, icon and status params.
*/

export const getMarker = ({
  priority, status, position, icon,
}) => {
  // Determine the path for the icon based on the status or the provided icon
  let iconData;
  if (icon && status === 'active') {
    // If there's an icon and status is active, use getIcon to find it
    const path = getIcon(icon) || generateMapMarker(status);
    iconData = { path };
  } else {
    // If no specific icon is provided, use the default path based on status
    iconData = { path: generateMapMarker(status) };
  }

  // Get the color for the priority
  const color = getPriorityColor(priority);

  // Add the color to the icon data
  iconData = { ...ICONS_BASE, fillColor: color, ...iconData };

  // If a position is provided, return an object with label and icon information
  if (position) {
    return {
      label: { text: position.toString(), fontWeight: 'bold' },
      icon: { ...iconData, labelOrigin: { x: 12, y: -2 } },
    };
  }

  // If no position is provided, return the icon data only
  return { ...ICONS_BASE, ...iconData };
};

/**
 * Returns Yes, No, No Value or Invalid based on the boolean value supposed to be provided.
*/
export const h2aOptions = [
  { name: 'No Data', value: '' },
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export function getH2ANameFromH2AValue(value) {
  return h2aOptions.find((n) => n.value === value)?.name;
}

/**
 * this function is gets parametters list and texts values and returns text based on length of the list.
*/
export const returnTextBasedOnCount = (
  count,
  textForLengthIsOne = 'Item',
  textIsZero,
  textIsPlur,
) => {
  const textPlural = textIsPlur || `${count} ${textForLengthIsOne}s`;
  const textZero = textIsZero || `${textPlural}`;
  switch (count) {
    case 0:
      return textZero;
    case 1:
      return `${count} ${textForLengthIsOne}`;
    default:
      return textPlural;
  }
};
