import React from 'react';
import { PropTypes } from 'prop-types';
import { SelectDropdown } from '../../components/FormInputs';
import PaginationItem from './PaginationItem';
import { range, cutPages } from './range';

const PaginationComponent = ({
  paginationObject,
  setCurrentPage,
  handleChange,
  label,
  children,
}) => {
  // generate limit options for pagination component dropdown
  const optionHash = ['5', '10', '25', '50'];

  // convert array to hash for processing by dropdown component
  const newOptionHash = optionHash.map(option => {
    return {
      name: `${option} per page`,
      value: option,
    };
  });

  const pagesCut = cutPages({ pagesCount: paginationObject.totalPages, pagesCutCount: paginationObject.perPage, currentPage: paginationObject.currentPage });

  const pages = range(pagesCut.start, pagesCut.end);

  const isFirstPage = paginationObject.currentPage === 1;
  const isLastPage = paginationObject.currentPage === paginationObject.totalPages;

  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <>
      <h3 className="title-with-count">{children}</h3>
      {
        paginationObject?.totalItems > 5
          ? (
            <div
              className="text-center pagination-dropdown"
            >
              <SelectDropdown
                emptyLabel={label}
                options={newOptionHash}
                handleChange={handleChange}
              />
            </div>
          ) : <></>
      }
      {
        paginationObject.totalPages > 1
          ? (
            <ul className="pagination">
              <PaginationItem
                page="First"
                currentPage={paginationObject.currentPage}
                onPageChange={() => paginate(1)}
                isDisabled={isFirstPage}
              />
              <PaginationItem
                page="Previous"
                currentPage={paginationObject.currentPage}
                onPageChange={() => (isFirstPage ? paginate(paginationObject.currentPage) : paginate(paginationObject.currentPage - 1))}
                isDisabled={isFirstPage}
              />
              {pages.map(page => {
                return (
                  <PaginationItem
                    page={page}
                    key={page}
                    currentPage={paginationObject.currentPage}
                    onPageChange={paginate}
                  />
                );
              })}
              <PaginationItem
                page="Next"
                currentPage={paginationObject.currentPage}
                onPageChange={() => (isLastPage ? paginate(paginationObject.currentPage) : paginate(paginationObject.currentPage + 1))}
                isDisabled={isLastPage}
              />
              <PaginationItem
                page="Last"
                currentPage={paginationObject.currentPage}
                onPageChange={() => paginate(paginationObject.totalPages)}
                isDisabled={isLastPage}
              />
            </ul>
          ) : <></>
      }
    </>
  );
};

PaginationComponent.defaultProps = {
  label: 'Items per page',
  children: 'Update history',
};

PaginationComponent.propTypes = {
  paginationObject: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.string,
};

export default PaginationComponent;
