import React from 'react';
import { PropTypes } from 'prop-types';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <ul className="nav nav-tabs">
      {tabs.map(tab => (
        <li className="nav-item" key={tab.key}>
          <a
            className={`nav-link ${activeTab === tab.key ? 'active' : ''}`}
            aria-current="page"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(tab.key);
            }}
          >
            {tab.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};

Tabs.defaultProps = {
  activeTab: '',
  setActiveTab: () => {},
};

export default Tabs;
