import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableTdWithIcon = ({
  icon,
  styles,
  iconColor,
  fieldText,
  comment,
}) => {
  const textToRender = comment === 'via-import' ? `${fieldText} (via import)` : fieldText;
  return (
    <td>
      <FontAwesomeIcon icon={icon} color={iconColor} style={styles.fieldIcon} />
      <p style={styles.fieldText}>{textToRender}</p>
    </td>
  );
};

TableTdWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
  iconColor: PropTypes.string.isRequired,
  fieldText: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default TableTdWithIcon;
