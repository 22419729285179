import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import RouteModal from '../RouteModal';
import { DialogsProvider } from '../../providers';

/**
 * Button to open RouteModal component
 * @param {number} organizationId
 * @returns
 */
const BuildRoute = ({ organizationId }) => {
  // State to open/close route modal
  const [openRouteModal, setOpenRouteModal] = useState(false);

  return (
    <DialogsProvider>
      <Button
        variant="outline-success"
        className="mt-2 mr-2"
        onClick={() => setOpenRouteModal(true)}
      >
        Build a Route
      </Button>

      <RouteModal
        isOpen={openRouteModal}
        organizationId={organizationId}
        onClose={() => setOpenRouteModal(false)}
      />
    </DialogsProvider>
  );
};

BuildRoute.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default BuildRoute;
