import React from 'react';
import { PropTypes } from 'prop-types';
import SiteContactItem from '../SiteContactItem';

const SiteContactList = ({
  siteId, contacts, onShow, onEdit, onDelete,
}) => {
  return (
    contacts.length ? (
      contacts.map((contact) => (
        <SiteContactItem
          siteId={siteId}
          contact={contact}
          key={contact.id}
          onShow={onShow}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))
    ) : null
  );
};

SiteContactList.propTypes = {
  siteId: PropTypes.number.isRequired,
  contacts: PropTypes.array.isRequired,
  onShow: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SiteContactList;
