import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';

/**
 * Select dropdown component to render multiple options with a single selection.
 * This component render an array of object in form of { name:"", value: "" }.
 * Property `name` must be an string and property `value` can be string, number or object
 * @param enabledEmptyOption enables and empty option in the dropdown. By default this value
 * is `true`
 * @param emptyLabel represent an string with the name of the empty option. By default this
 * value is `None`
 */

const SelectDropdown = ({
  label,
  value,
  options,
  handleChange,
  enableEmptyOption,
  emptyLabel,
  isRequired,
  errorMessage,
  id,
}) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label className={`text-capitalize m-0 ${isRequired ? 'required' : ''}`}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        as="select"
        custom
        onChange={handleChange}
        value={value}
        id={id}
      >
        {enableEmptyOption && <option>{emptyLabel}</option>}
        {
          // eslint-disable-next-line react/no-array-index-key
          options.map((item, index) => <option key={`${item.name}-${index}`} value={item.value}>{item.name}</option>)
        }
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

SelectDropdown.defaultProps = {
  label: undefined,
  enableEmptyOption: true,
  emptyLabel: 'Choose a site type',
  value: undefined,
  isRequired: false,
  errorMessage: undefined,
  id: undefined,
};

SelectDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  emptyLabel: PropTypes.string,
  // By default this property is enabled. Some other components that won't required
  // this empty option in dropdown, can send this prop as false.
  // eslint-disable-next-line react/boolean-prop-naming
  enableEmptyOption: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool,
      ]),
    }).isRequired,
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SelectDropdown;
