import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../../../../utils/api/index';

const Select = ({
  item, customValues, handleCustomFieldChange, isEnabledEmptyOption,
}) => {
  const label = capitalizeFirstLetter(item.label);

  return (
    <>
      <Form.Group controlId={item.key}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={handleCustomFieldChange}
          name={item.key}
          value={customValues[item.key] ? customValues[item.key] : ''}
          className="col-md-2 ml-1"
        >
          {isEnabledEmptyOption && <option value={undefined}>None</option>}
          {
            // eslint-disable-next-line react/no-array-index-key
            item.values.map((value, index) => <option key={index} value={value.value}>{value.value}</option>)
          }
        </Form.Control>
      </Form.Group>
    </>
  );
};

Select.defaultProps = {
  // By default this property is enabled. Some other components that won't required
  // this empty option in dropdown, can send this prop as false.
  isEnabledEmptyOption: true,
};

Select.propTypes = {
  item: PropTypes.object.isRequired,
  customValues: PropTypes.object.isRequired,
  handleCustomFieldChange: PropTypes.func.isRequired,
  isEnabledEmptyOption: PropTypes.bool,
};

export default Select;
