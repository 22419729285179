import React from 'react';
import { ToastContext } from '../context';

/**
 * Custom hook to use functions exported by Toast Context
 * @returns useToasts hook
 */
export const useToasts = () => {
  return React.useContext(ToastContext);
};
