import React from 'react';
import { PropTypes } from 'prop-types';
import CarouselControlButton from '../CarouselControlButton';
import CarouselSiteImage from './CarouselSiteImage';

const SiteImagesCarousel = ({
  siteImages,
  primaryImgId,
}) => {
  const renderImage = (siteImage) => {
    const isPrimaryImage = siteImage.id === primaryImgId;
    return (
      <CarouselSiteImage
        key={siteImage.id}
        image={siteImage?.image.url}
        imageID={siteImage.id}
        isPrimaryImage={isPrimaryImage}
      />
    );
  };
  return (
    <div
      id="carouselExampleControls"
      className="carousel"
      data-ride="carousel"
      data-interval="0"
    >
      <div className="carousel-inner mb-3">
        {siteImages?.length
          ? siteImages.map((siteImage) => renderImage(siteImage))
          : <CarouselSiteImage /> }
      </div>
      {siteImages?.length > 1
        ? (
          <div>
            <CarouselControlButton title="Previous" type="prev" />
            <CarouselControlButton title="Next" type="next" />
          </div>
        )

        : null}

    </div>
  );
};

SiteImagesCarousel.defaultProps = {
  primaryImgId: -1,
};

SiteImagesCarousel.propTypes = {
  siteImages: PropTypes.array.isRequired,
  primaryImgId: PropTypes.number,

};

export default SiteImagesCarousel;
