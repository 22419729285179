import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

/**
 * Custom component to display a dialog modal.
 * Could be used as an alert dialog or confimartion dialog
 */
const DialogModal = ({
  isVisible, title, message, shouldShowCheckbox, buttonLabel, actions, onClose, footer,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  /**
   * Close modal
   */
  const handleClose = () => {
    // If callback available, runs callback
    if (onClose) {
      onClose();
    }
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Modal show={isVisible} onHide={handleClose} animation={false} centered>
      { title && (
      <Modal.Header closeButton>
        <h5 className="text-center">{title}</h5>
      </Modal.Header>
      )}
      <div className="modal-body">
        {message && <p>{message}</p>}
        { shouldShowCheckbox && (
          <label>
            <input
              type="checkbox"
              value={isChecked}
              onChange={handleChange}
            />
            &nbsp;Update address as well?
          </label>
        )}
      </div>
      <div className="modal-footer m-0">
        {footer && (
          <div className="mr-6">
            {footer}
          </div>
        )}
        {actions && actions.length ? actions.map((item) => (
          <Button
            key={uuidv4()}
            variant={item.variant || 'success'}
            className={item.className ? item.className : ''}
            onClick={() => { item.onClick(isChecked); setIsChecked(false); }}
          >
            {item.label}
          </Button>
        ))
          : (
            <Button onClick={handleClose} variant="success">
              {buttonLabel}
            </Button>
          )}
      </div>
    </Modal>
  );
};

DialogModal.defaultProps = {
  isVisible: false,
  title: undefined,
  message: undefined,
  buttonLabel: 'Close',
  onClose: undefined,
  shouldShowCheckbox: false,
  actions: [],
  footer: undefined,
};

DialogModal.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func,
  shouldShowCheckbox: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(['success', 'danger']),
      className: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  footer: PropTypes.string,
};

export default DialogModal;
