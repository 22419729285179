import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';

const TextInput = React.forwardRef(({
  name,
  label,
  value,
  setValue,
  type,
  isRequired,
  errorMessage,
  onKeyEnterPressed,
  ...props
}, ref) => {
  const onChange = (e) => {
    setValue(e.target.value);
  };
  /**
   * Handle Enter key pressed
   */
  const handleEnterKeyPressed = (e) => {
    if (onKeyEnterPressed && e.code === 'Enter') {
      onKeyEnterPressed();
    }
  };
  return (
    <>
      {label && (
      <Form.Label className={`m-0 ${isRequired ? 'required' : ''}`}>
        {label}
      </Form.Label>
      )}
      <Form.Control
        type={type}
        name={name}
        value={value}
        ref={ref}
        required={isRequired}
        isInvalid={!!errorMessage}
        onChange={onChange}
        onKeyDownCapture={handleEnterKeyPressed}
        // Take all Form.Control props and props forwarded by DateSelector component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      <Form.Control.Feedback type="invalid">
        { errorMessage }
      </Form.Control.Feedback>
    </>
  );
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  value: undefined,
  label: undefined,
  type: 'text',
  isRequired: false,
  errorMessage: undefined,
  onKeyEnterPressed: undefined,
  setValue: () => {},
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  setValue: PropTypes.func,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  onKeyEnterPressed: PropTypes.func,
};

export default TextInput;
