import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDirections } from '@fortawesome/free-solid-svg-icons';
import { useRoutes, useDialogs } from '../../hooks';
import { generateGoogleMapURL } from '../../utils/api/routes';
import AccordionBody from '../AccordionBody';

export const renderDirectionsPanel = (isEnabled) => {
  return (
    <AccordionBody
      title="Route Turn By Turn Directions"
      eventKey="route-directions"
      className={isEnabled ? '' : 'hidden'}
    >
      <div id="panel" />
    </AccordionBody>
  );
};
const DirectionButtons = ({ isEnabled, setIsEnabled }) => {
  // Hook useRoute to have access to RoutesContext
  const { currentRoute } = useRoutes();
  const { openDialog, closeDialog } = useDialogs();

  const handleMapsUrl = (e) => {
    const MAX_STOPS_ALLOWED = 11;

    if (currentRoute.stops.length > MAX_STOPS_ALLOWED) {
      e.preventDefault();
      openDialog({
        message: `Unfortunately google limits our stops to ${MAX_STOPS_ALLOWED}, please remove some stops and try again`,
        show: true,
        actions: [
          {
            label: 'Close',
            onClick: () => {
              closeDialog();
            },
          },
        ],
      });

      return false;
    }
    return true;
  };
  const directionIsNaN = currentRoute.stops.find((stop) => {
    if (Number.isNaN(Number(stop.distance))) {
      return true;
    }
    return false;
  });

  const directionMapButton = () => {
    let url = '';
    if (isEnabled) {
      url = generateGoogleMapURL(currentRoute.stops);
      return (
        <a href={url} rel="noreferrer" target="_blank" onClick={(e) => handleMapsUrl(e)}><img alt="google pin" className="smart-link-icon css-168a27h e1bjtjov0" src="https://www.google.com/images/branding/product/ico/maps15_bnuw3a_32dp.ico" data-testid="inline-card-icon-and-title-image" />Open in Maps</a>
      );
    }
    return (
      <button type="button" className="btn btn-primary  align-self-baseline" onClick={() => setIsEnabled(true)}>Get Directions! <FontAwesomeIcon icon={faDirections} className="directionsIcon " /></button>
    );
  };

  return (
    <div className="container m-2 sidebar_container">
      {currentRoute.stops.length > 1 && !directionIsNaN ? (directionMapButton()) : (<></>)}
    </div>
  );
};

DirectionButtons.defaultProps = {
  isEnabled: undefined,
  setIsEnabled: undefined,
};

DirectionButtons.propTypes = {
  isEnabled: PropTypes.bool,
  setIsEnabled: PropTypes.func,
};

export default DirectionButtons;
