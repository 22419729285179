import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import titleize from 'titleize';
import { redirectToSeasonEdit, redirectToSeasons } from '../../utils/navigation';
import { useToasts } from '../../hooks';
import DialogModal from '../DialogModal';
import MenuButton from '../FormInputs/MenuButton';
import { accessToDeleteBtn } from '../../utils/accessToDeleteBtn';
import { formatLongDate } from '../../utils/dates';

const SeasonItem = ({ season, isManager }) => {
  const { showToast } = useToasts();
  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
  });

  const onDelete = () => {
    fetch(`/seasons/${season.id}`, {
      method: 'DELETE',
    }).then(response => {
      if (response.status === 200) {
        redirectToSeasons();
      } else {
        throw new Error(`Request failed with status: ${response.statusText}`);
      }
    }).catch(error => {
      showToast({ message: error.message, type: 'error' });
    });
  };

  const handleCloseSeasonModal = () => {
    setAlertBody({ show: false });
  };
  const handleOpenDeleteSeasonModal = () => {
    setAlertBody({
      message: `Are you sure you want to delete ${titleize(season.name)}?`,
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => { onDelete(); handleCloseSeasonModal(); },
        }, {
          label: 'No',
          variant: 'danger',
          onClick: handleCloseSeasonModal,
        },
      ],
    });
  };

  const handleExport = async () => {
    const seasonId = season.id;
    const url = `/export.csv?type=season_visits&id=${seasonId}`;

    try {
      const response = await fetch(url);

      if (response.ok) {
        showToast({ message: 'Preparing export', type: 'warning' });

        // Introduce a delay of 1 second before reloading the window
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        throw new Error('Export initiation failed');
      }
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
    }
  };

  const actionItemButtons = [
    { label: 'Edit', onClick: () => redirectToSeasonEdit(season.id) },
    { label: 'Export Data', onClick: handleExport },
    { label: 'Delete', onClick: handleOpenDeleteSeasonModal },
  ];

  const actionItems = accessToDeleteBtn(actionItemButtons, isManager);

  return (
    <tr key={season.id}>
      <td>
        <a href={`/seasons/${season.id}`}>{titleize(season.name)}</a>
      </td>
      <td>{formatLongDate(season.start_date)}</td>
      <td>{formatLongDate(season.end_date)}</td>
      <td>
        <MenuButton items={actionItems} />
      </td>

      <DialogModal
        isVisible={alertBody.show}
        title={alertBody.title}
        message={alertBody.message}
        onClose={handleCloseSeasonModal}
        actions={alertBody.actions}
      />
    </tr>
  );
};

SeasonItem.propTypes = {
  season: PropTypes.object.isRequired,
  isManager: PropTypes.bool.isRequired,
};

export default SeasonItem;
