import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { redirectToSiteNote, redirectToSiteNoteEdit } from '../../../../../utils/navigation';
import { formatShortDate } from '../../../../../utils/dates';
import { styles } from './SiteNoteItem.component.style';
import { useCurrentUser, useDialogs } from '../../../../../hooks';
import MenuButton from '../../../../FormInputs/MenuButton';
import { accessToDeleteBtn } from '../../../../../utils/accessToDeleteBtn';
import { capitalizeFirstLetter } from '../../../../../utils/api';

const SiteNoteItem = ({
  title,
  content,
  status,
  id,
  timestamp,
  color,
  siteId,
  onDeleteNote,
}) => {
  const [open, setOpen] = useState(false);
  const localizedNoteDate = formatShortDate(timestamp);
  const { openDialog, closeDialog } = useDialogs();
  const { isManager: currentRoleIsManager } = useCurrentUser();

  const onCollapse = (e) => {
    e.preventDefault();
    const value = !open;
    setOpen(value);
  };
  const onShow = (e, noteId) => {
    e.preventDefault();
    redirectToSiteNote(siteId, noteId);
  };

  const onEdit = (e, noteId) => {
    e.preventDefault();
    redirectToSiteNoteEdit(siteId, noteId);
  };

  const handleOpenDeleteModal = (e, noteId) => {
    openDialog({
      message: 'Are you sure you want to delete this note? This cannot be undone.',
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            onDeleteNote(e, noteId);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  const actionItemButtons = [
    { label: 'Edit', onClick: (e) => onEdit(e, id) },
    { label: 'Delete', onClick: (e) => handleOpenDeleteModal(e, id) },
  ];
  const actionItems = accessToDeleteBtn(actionItemButtons, currentRoleIsManager);

  return (
    <div className="card bg-light" style={styles.noteItemCard}>
      <div className="note-header d-flex bg-white">
        <span id="flag" style={{ backgroundColor: color }} />
        <button type="button" className="card-header bg-white align-items-center btn" onClick={(e) => onCollapse(e)}>
          <h5 className="mb-0 d-inline-block text-truncate text-left">{title}</h5>
          <div style={styles.noteDate}>
            {localizedNoteDate}
          </div>
        </button>
        <div className="card-items d-flex flex-nowrap align-items-center">
          <MenuButton items={actionItems} />
        </div>
      </div>
      {open ? (
        <button type="button" className="card-body bg-light btn btn-light text-left" style={styles.cardBody} onClick={(e) => onShow(e, id)}>
          <div><b>Status: </b>{capitalizeFirstLetter(status)}</div>
          <div><b>Content: </b>{content}</div>
        </button>
      ) : null}
    </div>
  );
};

SiteNoteItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  siteId: PropTypes.number.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
};

export default SiteNoteItem;

