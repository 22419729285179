import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({ onClick, isButtonClicked }) => (
  <button
    type="button"
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '10px',
      left: '50%',
      zIndex: '10',
      padding: '8px',
      background: '#fff',
      border: '0px',
      borderRadius: '2px',
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
      display: isButtonClicked ? 'none' : 'block',
    }}
  >
    Search Region
  </button>
);

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isButtonClicked: PropTypes.bool.isRequired,
};

export default CustomButton;
