import React from 'react';
import { PropTypes } from 'prop-types';
import TrWithLabelAndValue from '../TableComponents/TrWithLabelAndValue';

const Select = ({ item, values, styles }) => {
  return (
    <TrWithLabelAndValue item={item} values={values} styles={styles} />
  );
};

Select.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Select;
