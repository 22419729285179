import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import * as Input from './CustomFormInput';
import * as customFieldTypes from '../../../constants/customFields';

const CustomFieldForm = ({
  values, modelFormItems, onChangeValue,
}) => {
  const [customValues, setCustomValues] = useState(values);

  const handleOnChangeValue = (vals) => {
    onChangeValue(vals);
  };

  const onOptionsChange = (customFieldId, multiSelectField) => {
    const newVals = {
      ...customValues,
      [customFieldId]: multiSelectField,
    };
    setCustomValues(newVals);
    handleOnChangeValue(newVals);
  };

  const handleCustomFieldChange = (evt) => {
    const { value } = evt.target;
    const newVals = {
      ...customValues,
      [evt.target.name]: value,
    };
    setCustomValues(newVals);
    handleOnChangeValue(newVals);
  };

  const formatSelectedOptions = (value) => {
    if (value) {
      return typeof value === 'string' ? JSON.parse(value) : value;
    }
    return [];
  };

  const formItems = modelFormItems.map(item => {
    switch (item.type) {
      case customFieldTypes.FIELD_TYPE_CHECKBOX_GROUP:
        return (
          <Input.MultiSelect
            key={item.key}
            name={item.key}
            item={item}
            initiallySelectedOptions={formatSelectedOptions(customValues[item.key])}
            onOptionsChange={onOptionsChange}
          />
        );
      case customFieldTypes.FIELD_TYPE_TEXT_AREA:
        return (
          <Input.TextArea
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      case customFieldTypes.FIELD_TYPE_TEXT:
        return (
          <Input.Line
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      case customFieldTypes.FIELD_TYPE_NUMBER:
        return (
          <Input.Number
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      case customFieldTypes.FIELD_TYPE_DATE:
        return (
          <Input.DateField
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      case customFieldTypes.FIELD_TYPE_HEADER:
        return (
          <Input.Header
            key={item.key}
            item={item}
          />
        );
      case customFieldTypes.FIELD_TYPE_SELECT:
        return (
          <Input.Select
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      default:
        return (
          <Input.Line
            key={item.key}
            item={item}
            customValues={customValues}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
    }
  });
  return (
    <>
      {formItems.length ? <h3 className="mt-2 border-bottom">Custom Fields</h3> : null}
      {formItems}
    </>
  );
};

CustomFieldForm.propTypes = {
  values: PropTypes.object.isRequired,
  modelFormItems: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default CustomFieldForm;
