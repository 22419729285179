import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { iconPaths } from '../../../constants/iconsList';

const IconsComponent = ({ iconName }) => {
  const iconPath = iconPaths[iconName];
  const iconClass = `icon-${iconName.toLowerCase().replace(/ /g, '-')}`;
  return iconPath ? (
    <Icon path={iconPath} size={1} className={iconClass} />
  ) : null;
};

IconsComponent.propTypes = {
  iconName: PropTypes.string.isRequired,
};

export default IconsComponent;
