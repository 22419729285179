import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { ToastProvider } from '../../providers';
import { withContextProvider } from '../../hoc';
import { redirectToSeasonNew } from '../../utils/navigation';
import SeasonItem from './SeasonItem';
import SeasonHeader from './SeasonHeader';

const SeasonIndex = ({
  seasons,
  isManager,
  organization,
}) => {
  const [sorting, setSorting] = useState({ field: 'end_date', order: 'desc' });

  const handleSortingChange = (sortString) => {
    const [field, order] = sortString.split('-');
    setSorting({ field, order });
  };

  const seasonsArray = Object.values(seasons);

  const sortedSeasons = _.orderBy(seasonsArray, [sorting.field], [sorting.order]);

  const handleCreatNewSeason = (e) => {
    e.preventDefault();
    redirectToSeasonNew();
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between pt-4 pb-4">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={(e) => handleCreatNewSeason(e)}
        >
          New Season
        </button>
        <div className="d-flex flex-row margin-left-flex">
          <select
            name="ordering"
            id="ordering"
            className="form-control order-dropdown"
            value={`${sorting.field}-${sorting.order}`}
            onChange={(e) => handleSortingChange(e.target.value)}
          >
            <option value="" disabled>Sort by</option>
            <option value="end_date-desc">Date Desc</option>
            <option value="end_date-asc">Date Asc</option>
            <option value="name-asc">Name A-Z</option>
            <option value="name-desc">Name Z-A</option>
          </select>
        </div>
      </div>

      <table className="table table-striped table-sm" style={{ borderBottom: 'solid 2px lightgray' }}>
        <SeasonHeader />

        <tbody>
          {sortedSeasons.map((season) => (
            <SeasonItem key={season.id} season={season} isManager={isManager} organization={organization} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

SeasonIndex.propTypes = {
  seasons: PropTypes.array.isRequired,
  isManager: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
};

export default withContextProvider(ToastProvider)(SeasonIndex);
