// pagination object
export const INITIAL_PAGINATION = {
  currentPage: 1,
  perPage: 5,
  firstIndex: 0,
  lastIndex: 5,
  currentData: [],
  totalPages: 0,
  totalItems: 0,
};
