import React from 'react';
import { CurrentUserContext } from '../context';

/**
 * Custom hook to use functions exported by VamosMapContext
 * @returns useVamosMap hook
 */
export const useCurrentUser = () => {
  return React.useContext(CurrentUserContext);
};
