import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Row, Col, Table, Form, Button,
} from 'react-bootstrap';
import SiteFormContactRow from './SiteFormContactRow/index';
import { SelectDropdown } from '../../../FormInputs';

const SiteFormContact = ({
  contacts,
  deleteContact,
  addNewContact,
  orgContacts,
}) => {
  // Declare a new state variable,
  const [newContact, setNewContact] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    addNewContact(newContact);
  };

  const contactList = contacts.map((contact) => <SiteFormContactRow key={contact.id} contact={contact} deleteContact={deleteContact} />);

  // Create array of orgContacts types in the form of { name: "", value: ""}
  const orgContactList = orgContacts.map(contact => {
    return {
      name: contact.name,
      value: contact.id,
    };
  });

  return (
    <Table striped hover>
      <thead>
        <h3 className="mt-2 border-bottom">Contacts</h3>
      </thead>
      <tbody>
        {contactList}
        <tr>
          <td>
            <Row>
              <Col lg={12}>

                <Form.Row>
                  <Col xs={9}>
                    <SelectDropdown
                      label=""
                      options={orgContactList}
                      enableEmptyOption
                      emptyLabel="Choose a Contact"
                      handleChange={(e) => setNewContact(e.target.value)}
                    />
                  </Col>
                  <Col xs={3}>
                    <Button variant="primary" onClick={onSubmit}>
                      Add The Contact to The Site
                    </Button>
                  </Col>
                </Form.Row>

              </Col>
            </Row>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

SiteFormContact.propTypes = {
  contacts: PropTypes.array.isRequired,
  deleteContact: PropTypes.func.isRequired,
  addNewContact: PropTypes.func.isRequired,
  orgContacts: PropTypes.array.isRequired,
};

export default SiteFormContact;
