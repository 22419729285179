/**
 * Note: This is not the InfoWindow component. This is used has a child for a Map Marker
 * This component displays information about a site
 */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SpinnerButton from '../../../FormInputs/SpinnerButton';
import { styles } from './SiteInfoWindow.component.style';
import { getH2ANameFromH2AValue } from '../../../../utils/api/site';
import { formatNumericDate } from '../../../../utils/dates';

const SiteInfoWindow = ({ site, addStop }) => {
  // Checking which on is the Site/Stop @id and @name
  // Since this component is being reused for SitesInMap, SiteShow, and Routes component
  // They have the same information with different attributes/property name.
  const id = site.site_id || site.id;
  const name = site.name || site.site_name;

  const h2a = getH2ANameFromH2AValue(site.h2a) || 'No Data';

  // State to handle loading bool
  const [loading, setLoading] = useState(false);
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {site.name || site.site_name}
      </Tooltip>
    );
  };
  return (
    <div className="pb-3" style={styles.markerInfoWindowContent}>
      <div>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <h5 className="text-truncate">
            <a href={`/sites/${id}`}>{name}</a>
          </h5>
        </OverlayTrigger>
      </div>
      {site.last_visit_date && (
        <div style={styles.visitDate}>
          <div>Last-Visit: {site.last_visit_date.substring(0, 10)} </div>
        </div>
      )}
      <div style={styles.flexItem}>
        <div>{site.arrival && <div>Arrival: {formatNumericDate(site.arrival)} </div>}</div>
        <div>{site.departure && <div>Departure: {formatNumericDate(site.departure)} </div>}</div>
      </div>

      <div style={styles.flexItem}>
        {site.worker_count && (
          <div>
            {site.worker_count && <div>Worker-count: {site.worker_count} </div>}
          </div>
        )}
        <div>H2A: {h2a} </div>
      </div>

      <div style={styles.buttonsContainer}>
        <a href={`/sites/${id}`} target="_blank" rel="noreferrer">
          <button
            type="button"
            className="mt-2 btn btn-outline-primary"
            data-toggle="modal"
            style={styles.ViewDetailButtons}
          >
            View Details
            <i
              style={styles.IconStyle}
              className="fa fa-external-link"
              aria-hidden="true"
            />
          </button>
        </a>
        {addStop ? (
          <SpinnerButton
            style={styles.RoutButton}
            className="mt-2 btn btn-outline-primary"
            label="Add to Route"
            labelLoading="Saving..."
            isLoading={loading}
            onClick={() => addStop(site, setLoading)}
          />
        ) : null}
      </div>
    </div>
  );
};

SiteInfoWindow.propTypes = {
  site: PropTypes.object.isRequired,
  addStop: PropTypes.func,
};
SiteInfoWindow.defaultProps = {
  addStop: undefined,
};

export default SiteInfoWindow;
