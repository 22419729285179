import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import SiteVisitItem from './SiteVisitItem';
import { redirectToSiteVisitNew } from '../../../../utils/navigation';
import { removeVisit, returnTextBasedOnCount } from '../../../../utils/api/site';
import { styles } from './SiteVisit.component.style';
import { useToasts } from '../../../../hooks';

const SiteVisits = ({
  site,
}) => {
  // Hook Toast
  const { showToast } = useToasts();
  const [visits, setVisits] = useState([]);
  const siteVisitHeaderText = returnTextBasedOnCount(visits.length, 'Visit');

  useEffect(() => {
    setVisits(site.visits || []);
  }, [site]);

  const onCreate = (e) => {
    e.preventDefault();
    redirectToSiteVisitNew(site.id);
  };

  const onDeleteVisit = (e, visitId) => {
    e.preventDefault();
    removeVisit({ organization_id: site.organization_id }, visitId)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.error);
        }
        setVisits(visits.filter(visit => visit.id !== visitId));
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  return (
    <div>
      <div className="site-visits-notes-title" style={styles.siteVisitTitle}>
        <h3 className="title-with-count">
          {siteVisitHeaderText}
        </h3>
        <button type="button" className="m-1 btn btn-outline-light" onClick={(e) => onCreate(e)}>
          <i className="fa fa-2x fa-plus" style={styles.createIcon} />
        </button>
      </div>
      {visits.map((visit) => (
        <SiteVisitItem
          key={visit.id}
          leader={visit.leader}
          purpose={visit.purpose}
          workersInteracted={visit.workers_interacted}
          workersTotal={visit.total_workers}
          visitors={visit.visitors}
          donations={visit.provided}
          needsConcerns={visit.needs_concerns}
          otherNotes={visit.other_notes}
          id={visit.id}
          date={visit.date}
          siteId={site.id}
          onDeleteVisit={onDeleteVisit}
          completed={visit.completed}
        />
      ))}
    </div>
  );
};

SiteVisits.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SiteVisits;
