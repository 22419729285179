export const styles = {
  table: {
    padding: 10,
    paddingTop: 0,
    margin: '0 auto',
    backgroundColor: 'rgb(250, 250, 250)',
  },
  thClassName: {
    className: 'col-md-8 pb-2',
  },
  titleRow: {
    marginTop: 30,
    backgroundColor: '#dee2e6',
  },
};
