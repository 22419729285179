import React from 'react';
import { PropTypes } from 'prop-types';
import TrWithLabelAndValue from '../TableComponents/TrWithLabelAndValue';
import { formatNumericDate } from '../../../../../utils/dates';

const Date = ({ item, values, styles }) => {
  let x = 0;
  if (Object.keys(item).includes('key')) {
    x = item.key;
  }
  const formattedDate = {};
  formattedDate[x] = (values[x] ? formatNumericDate(values[x]) : 'No Info');
  return (
    <TrWithLabelAndValue item={item} values={formattedDate} styles={styles} />
  );
};

Date.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Date;
