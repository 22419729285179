/**
 * @Note: Limits and Restrictions for Waypoints
    - The maximum number of waypoints allowed when using the Directions service in the Maps JavaScript API is 25,
      plus the origin and destination. The limits are the same for the Directions API web service.
    - Waypoints are not supported for transit directions.
 */

import React, { useCallback, useRef, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const defaultError = {
  hasError: false,
  errorMessage: '',
};

export const useDirectionsService = (coordinates) => {
  // State to handle directions provided by Google
  const [directions, setDirections] = useState();
  const [error, setError] = useState(defaultError);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAP_JS_API_KEY,
  });

  // Mutable object to handle instance of google variable
  const googleInstance = useRef();

  /**
   * Generate origin, destinations and waypoints using @coordinates array
   * @returns object containing origin, destination and array of waypoints
   */
  const generateOriginDestination = useCallback(() => {
    // Set first place as origin
    const origin = coordinates[0];
    // Set last place as destination
    const destination = coordinates[coordinates.length - 1];
    // Set the rest of coordinates as waypoints in the format required by Google
    const waypoints = coordinates.slice(1, -1).map(coord => ({
      location: new googleInstance.current.maps.LatLng(coord.lat, coord.lng),
      stopover: true,
    }));

    return {
      origin,
      destination,
      waypoints,
    };
  }, [coordinates]);

  /**
   * Handle request status to display custom error message
   * @param {*} statusCode status code
   */
  const handleStatus = (statusCode) => {
    let errorMessage;
    switch (statusCode) {
      case googleInstance.current.maps.DirectionsStatus.ZERO_RESULTS:
        errorMessage = 'No route could be found between the origin and destination';
        break;
      case googleInstance.current.maps.DirectionsStatus.INVALID_REQUEST:
        errorMessage = 'The request provided was invalid';
        break;
      case googleInstance.current.maps.DirectionsStatus.NOT_FOUND:
        errorMessage = 'At least one of the origin, destination, or waypoints could not be geocoded';
        break;
      case googleInstance.current.maps.DirectionsStatus.UNKNOWN_ERROR:
        errorMessage = 'There was a problem with your requets';
        break;
      default:
        errorMessage = 'There was an error trying to make your request';
        break;
    }

    setError({ hasError: true, errorMessage });
  };

  /**
   * Calculate directions using Google Map direction services
   * with the properties: @origin, @destination and @waypoints
   */
  const calculateDirections = useCallback(() => {
    // Clean error object
    setError(defaultError);

    const { origin, destination, waypoints } = generateOriginDestination();

    const DirectionsService = new googleInstance.current.maps.DirectionsService();
    DirectionsService.route({
      origin: new googleInstance.current.maps.LatLng(origin.lat, origin.lng),
      destination: new googleInstance.current.maps.LatLng(destination.lat, destination.lng),
      travelMode: googleInstance.current.maps.TravelMode.DRIVING,
      waypoints,
      optimizeWaypoints: false, // Cool opportunity to enable route optimization here.
    }, (result, status) => {
      if (status === googleInstance.current.maps.DirectionsStatus.OK) {
        setDirections(result);
      } else {
        handleStatus(status);
      }
    });
  }, [generateOriginDestination]);

  /**
   * This function will be execute when detect changes in coordinates
   */
  React.useEffect(() => {
    if (isLoaded) {
      // Assign global variable to a mutable ref
      googleInstance.current = window.google;
      // Run calculate directions if there are 2 or more coordinates
      if (coordinates.length > 1) {
        calculateDirections();
      }
    }
  }, [isLoaded, coordinates, calculateDirections]);

  return {
    directions,
    error,
  };
};
