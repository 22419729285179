export const styles = {
  selectTag: {
    position: 'unset',
  },
  inputTag: {
    cursor: 'pointer',
    border: '1px solid #40b870',
    borderRadius: '0.25rem',
    backgroundColor: '#fff',
  },
  assignedTag: {
    color: '#6c757d',
    cursor: 'pointer',
    paddingLeft: 10,
  },
};
