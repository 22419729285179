import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../../../../utils/api/index';

const Header = ({ item }) => {
  const label = capitalizeFirstLetter(item.label);

  return (
    <Form.Group className="mb-n2" controlId={item.key}>
      <Form.Label><h5>{ label }</h5></Form.Label>
    </Form.Group>
  );
};

Header.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Header;
