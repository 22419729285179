import React from 'react';
import { PropTypes } from 'prop-types';
import { styles } from './SiteContactItem.component.style';
import { useCurrentUser, useDialogs } from '../../../../../hooks';
import MenuButton from '../../../../FormInputs/MenuButton';
import { accessToDeleteBtn } from '../../../../../utils/accessToDeleteBtn';

const SiteContactItem = ({
  key, contact, onShow, onEdit, onDelete,
}) => {
  const { openDialog, closeDialog } = useDialogs();
  const { isManager: currentRoleIsManager } = useCurrentUser();

  const handleOpenDeleteModal = (e, contactId) => {
    openDialog({
      message: 'Are you sure you want to remove this contact from the site?',
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => {
            onDelete(e, contactId);
            closeDialog();
          },
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  const actionItemButtons = [
    { label: 'Edit', onClick: (e) => onEdit(e, contact.id) },
    { label: 'Delete', onClick: (e) => handleOpenDeleteModal(e, contact.id) },
  ];
  const actionItems = accessToDeleteBtn(actionItemButtons, currentRoleIsManager);

  return (
    <div key={key} className="card bg-light" style={styles.contactCard}>
      <div className="note-header d-flex bg-white">
        <a href={`/contacts/${contact.id}`} type="button" className="card-header bg-white align-items-center" onClick={(e) => onShow(e, contact.id)} style={styles.showCard}>
          <h5 className="mb-0">{contact.name}</h5>
          <div style={{ color: '#495057' }}>
            {contact.contact_type}
          </div>
        </a>
        <div className="card-items d-flex flex-nowrap align-items-center">
          <MenuButton items={actionItems} />
        </div>
      </div>
    </div>
  );
};

SiteContactItem.propTypes = {
  key: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired,
  onShow: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SiteContactItem;
