import {
  mdiMapMarker,
  mdiHumanMaleChild,
  mdiPassport,
  mdiSnowflakeVariant,
  mdiCalendarRange,
  mdiCow,
  mdiTractorVariant,
  mdiHospitalBox,
  mdiChurch,
  mdiCart,
  mdiHandshake,
  mdiSilverwareForkKnife,
  mdiBookOpenBlankVariant,
  mdiCircle,
  mdiCheckBold,
  mdiSquare,
  mdiStar,
  mdiTriangle,
  mdiAlphaXCircle,
} from '@mdi/js';

// Define icon mappings in a reusable object
export const iconPaths = {
  Family: mdiHumanMaleChild,
  H2A: mdiPassport,
  Nonseasonal: mdiSnowflakeVariant,
  Seasonal: mdiCalendarRange,
  Dairy: mdiCow,
  Worksite: mdiTractorVariant,
  Clinic: mdiHospitalBox,
  Church: mdiChurch,
  'Grocery Store': mdiCart,
  'Partner Organization': mdiHandshake,
  Restaurant: mdiSilverwareForkKnife,
  'School/Daycare': mdiBookOpenBlankVariant,
  Circle: mdiCircle,
  'Check Mark': mdiCheckBold,
  Square: mdiSquare,
  Star: mdiStar,
  Triangle: mdiTriangle,
  X: mdiAlphaXCircle,
  default: mdiMapMarker,
};

// Export as an array for other use-cases
export const iconsList = Object.entries(iconPaths).map(([value, path]) => ({
  value, path,
}));
