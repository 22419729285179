import React from 'react';
import { PropTypes } from 'prop-types';
import ReactLinkify from 'react-linkify';
import { capitalizeFirstLetter } from '../../../../../../utils/api/index';

const TrWithLabelAndValue = ({ item, values, styles }) => {
  const label = capitalizeFirstLetter(item.label);
  const text = () => {
    if (values[item.key]) {
      return values[item.key].length > 100 ? (`${values[item.key].substring(0, 35)}...`) : values[item.key];
    }
    return 'No Info';
  };

  return (
    <tr>
      <th className={styles.thClassName.className}>{`${label}:` } </th>
      <td className="mb-0 d-inline-block text-left">
        <ReactLinkify>
          {text()}
        </ReactLinkify>
      </td>
    </tr>
  );
};

TrWithLabelAndValue.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default TrWithLabelAndValue;
