import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { RoutesContext } from '../context';
import { routesReducer } from '../reducers/routes';
import { addSiteToRoute, removeStopRoute, reorderRouteStops } from '../utils/api/routes';
import { updateCurrentRoute } from '../actions/routes';
import { ToastProvider } from './ToastProvider';
import { withContextProvider } from '../hoc/withContextProvider';
import { useToasts } from '../hooks';
import { handleResponse } from '../utils/api/errorHandler';
import { transformRouteStopToCamelCase } from '../utils/routeStops';

const MAX_ROUTES_ALLOWED = 25;

const RoutesProvider = ({ children, sitesArray, routeSelected }) => {
  // Hook Toast
  const { showToast } = useToasts();

  // Initial state to pass to useReducer
  const initialState = {
    sites: sitesArray,
    currentRoute: routeSelected,
    error: {
      hasError: false,
      message: '',
    },
  };

  // State to handle list of sites
  const [state, dispatch] = useReducer(routesReducer, initialState);
  const { organizationId, routeId, stops } = state.currentRoute;

  // State to handle VamosMarkers in the map
  const [vamosMarkersRef, setVamosMarkersRefs] = useState([]);

  /**
   * Update Route global state
   * @param {*} route new route
   */
  const updateRouteState = (route) => {
    const routeUpdate = transformRouteStopToCamelCase(route);
    // Dispatch to the reducer
    dispatch(updateCurrentRoute(routeUpdate));
  };

  /**
   * Send request to api to add new stop for current route
   * Send request to api to add new stop for current route;
   * and add the new stop to the global context
   * @param {object} site new stop
   */
  const addStop = useCallback(
    async (site, setLoading) => {
      try {
        setLoading(true);
        const stop = {
          site_id: site.id,
          route_id: routeId,
          location_id: site.location_id,
          site_name: site.name,
          // TODO: Dummy for this 3 attributes, since BT is working on it
          position: 1, // change to next
          distance: 0,
          time: 0,
        };

        // We only allow 25 Routes
        if (stops.length >= MAX_ROUTES_ALLOWED) {
          showToast({ message: 'Maximum 25 stops allowed', type: 'error' });
          return;
        }

        // Make API call
        const response = await addSiteToRoute(organizationId, routeId, stop);
        const data = await handleResponse(response);
        if (data) {
          updateRouteState(data.route);
          // Show toast
          showToast({ message: 'Stop added successfully', type: 'success' });
        }
      } catch (error) {
        showToast({ message: error.message, type: 'error' });
      } finally {
        // Loading off
        setLoading(false);
      }
    }, [organizationId, routeId, showToast, stops],
  );

  /**
   * Send request to api to delete stop for current route
   * and remove stop from the global context
   * @param {*} stopId
   */
  const removeStop = async (stopId) => {
    try {
      const data = {
        organization_id: organizationId,
      };

      const response = await removeStopRoute(routeId, stopId, data);
      const responseData = await handleResponse(response);
      if (responseData) {
        updateRouteState(responseData.route);
        // Show toast
        showToast({ message: 'Stop removed successfully', type: 'success' });
      }
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
    }
  };

  /**
   * Send request to api to reorder stops for current route
   * and update current route with response
   * @param {*} stopId  newPosition
   */
  const reorderStops = async (stopId, newPosition) => {
    const response = await reorderRouteStops(routeId, stopId, organizationId, newPosition);
    const data = await handleResponse(response);
    if (data) {
      updateRouteState(data.route);
      // Show toast
      showToast({ message: 'Stops reordered successfully', type: 'success' });
    }
  };

  return (
    <RoutesContext.Provider value={{
      sites: state.sites,
      currentRoute: state.currentRoute,
      addStop,
      removeStop,
      reorderStops,
      setVamosMarkersRefs,
      vamosMarkersRef,
    }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

RoutesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sitesArray: PropTypes.array.isRequired,
  routeSelected: PropTypes.object.isRequired,
};

export default withContextProvider(ToastProvider)(RoutesProvider);
