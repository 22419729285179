import React from 'react';
import { PropTypes } from 'prop-types';
import { PhotoProvider } from 'react-photo-view';
import {
  setPrimaryImage, removeImageFromSite, getSiteInfo, updateImage,
} from '../../../../utils/api/site';
import { useDialogs, useToasts } from '../../../../hooks';
import DndGallery from './DndGallery';

const PhotoGallery = ({
  siteImages,
  setSiteImages,
  organizationId,
  siteId,
}) => {
  const { showToast } = useToasts();
  const updateSiteInfo = () => {
    getSiteInfo(siteId, organizationId)
      .then((infoResponse) => infoResponse.json())
      .then(data => {
        if (!data.site) {
          throw new Error(data.error);
        }
        setSiteImages(data.site.site_images);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  const imagesArray = siteImages;
  const onSetPrimaryImage = (imageId) => {
    if (siteId) {
      const formData = { organization_id: organizationId };
      setPrimaryImage(formData, siteId, imageId)
        .then((response) => response.json())
        .then(data => {
          if (data.site) {
            showToast({ message: 'Image is successfully set as primary image.', type: 'success' });
            updateSiteInfo();
          } else {
            throw new Error(data.error);
          }
        })
        .catch((error) => {
          showToast({ message: error.message, type: 'error' });
        });
    }
  };

  const removeImage = (imageId) => {
    if (siteId) {
      removeImageFromSite(siteId, imageId, organizationId)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error);
          }
          updateSiteInfo();
          showToast({ message: 'Image is successfully deleted.', type: 'success' });
        })
        .catch((error) => {
          showToast({ message: error.message, type: 'error' });
        });
    } else {
      const index = imagesArray.findIndex(image => image.id === imageId);
      imagesArray.splice(index, 1);
      setSiteImages(imagesArray);
    }
  };
  const { openDialog, closeDialog } = useDialogs();
  const getActionItems = (imageId) => [
    { label: 'Make cover photo', onClick: () => { onSetPrimaryImage(imageId); } },
    {
      label: 'Delete',
      onClick: () => {
        openDialog({
          message: 'Are you sure you want to remove this image?',
          show: true,
          actions: [
            {
              label: 'Yes',
              onClick: () => {
                removeImage(imageId);
                closeDialog();
              },
            },
            {
              label: 'No',
              variant: 'danger',
              onClick: closeDialog,
            },
          ],
        });
      },
    },
  ];

  const updateImagePosition = (imageId, newPosition) => {
    if (siteId) {
      showToast({ message: 'Changes saved successfully', type: 'success' });
      updateImage(organizationId, siteId, imageId, newPosition)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error);
          }
          updateSiteInfo();
        })
        .catch((error) => {
          showToast({ message: error.message, type: 'error' });
        });
    }
  };

  if (!siteImages?.length) {
    return <></>;
  }
  return (
    <PhotoProvider>
      <DndGallery
        initCards={siteImages}
        getActionItems={getActionItems}
        updateImagePosition={updateImagePosition}
        siteId={siteId}
      />
    </PhotoProvider>
  );
};

PhotoGallery.propTypes = {
  siteImages: PropTypes.array,
  organizationId: PropTypes.number,
  siteId: PropTypes.number,
  setSiteImages: PropTypes.func,
};

PhotoGallery.defaultProps = {
  siteImages: [],
  organizationId: null,
  siteId: null,
  setSiteImages: () => { },
};

export default PhotoGallery;
