export const styles = {
  contactsTitle: {
    backgroundColor: 'grey',
    cursor: 'pointer',
  },
  createDiv: {
    zIndex: '1',
    cursor: 'pointer',
  },
  createIcon: {
    color: 'light',
    cursor: 'pointer',
  },
  contactCard: {
    border: 'none',
  },
  showCard: {
    cursor: 'pointer',
  },
  editIcon: {
    color: '#40b870',
    cursor: 'pointer',
  },
};
