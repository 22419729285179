import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import CardDragLayer from './CardDragLayer';
import Card from './Card';

const DndGallery = ({
  initCards, getActionItems, updateImagePosition, siteId,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const [targetIndex, setTargetIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <main className="dnd-gallery">
        <CardDragLayer />
        <div className="row">
          {initCards.map((card, i) => {
            const shouldHaveLineOnLeft = hoverIndex === i && targetIndex === i;
            const shouldHaveLineOnRight = hoverIndex === i && targetIndex === i + 1;
            return (
              <Card
                {...{
                  activeImage, setActiveImage, targetIndex, setTargetIndex, hoverIndex, setHoverIndex,
                }}
                getActionItems={getActionItems}
                siteImage={card}
                updateImagePosition={updateImagePosition}
                key={`card-${card.id}`}
                index={i}
                shouldHaveLineOnLeft={shouldHaveLineOnLeft}
                shouldHaveLineOnRight={shouldHaveLineOnRight}
                siteId={siteId}
              />
            );
          })}
        </div>
      </main>
    </DndProvider>
  );
};

DndGallery.propTypes = {
  initCards: PropTypes.array,
  getActionItems: PropTypes.func,
  updateImagePosition: PropTypes.func,
  siteId: PropTypes.number.isRequired,
};

DndGallery.defaultProps = {
  initCards: [],
  getActionItems: () => {},
  updateImagePosition: () => {},

};

export default DndGallery;
