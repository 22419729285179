export const styles = {
  circle: {
    display: 'flex',
    paddingRight: '6px',
    paddingLeft: '6px',
    width: '20px',
    height: '20px',
    backgroundColor: 'silver', // Define this colors in a Constant file for future use
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textCircle: {
    fontSize: '11px',
  },

  inlineText: {
    whiteSpace: 'nowrap',
  },

  loadingMessage: {
    color: '#018544',
    textAlign: 'center',
    marginTop: '10',
    fontSize: '12px',
  },
};
