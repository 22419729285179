export const redirectTo = (urlCallback) => {
  window.location.href = urlCallback();
};

export const redirectToSites = () => {
  redirectTo(() => '/sites');
};

export const redirectToSite = (siteId) => {
  redirectTo(() => `/sites/${siteId}`);
};

export const redirectToSiteEdit = (siteId) => {
  redirectTo(() => `/sites/${siteId}/edit`);
};
export const redirectToSiteEditElement = (siteId, id, scrollTo) => {
  redirectTo(() => `/sites/${siteId}/edit?id=${id}&scrollTo=${scrollTo}`);
};

export const redirectToTag = (tagId) => {
  redirectTo(() => `/tags/${tagId}`);
};

export const redirectToSiteNote = (siteId, id) => {
  redirectTo(() => `/sites/${siteId}/notes/${id}`);
};
export const redirectToSiteNoteEdit = (siteId, id) => {
  redirectTo(() => `/sites/${siteId}/notes/${id}/edit`);
};
export const redirectToSiteNoteNew = (siteId) => {
  redirectTo(() => `/sites/${siteId}/notes/new`);
};

export const redirectToSiteVisit = (siteId, id) => {
  redirectTo(() => `/sites/${siteId}/visits/${id}`);
};

export const redirectToSiteVisitEdit = (siteId, id) => {
  redirectTo(() => `/sites/${siteId}/visits/${id}/edit`);
};

export const redirectToSiteVisitNew = (siteId) => {
  redirectTo(() => `/sites/${siteId}/visits/new`);
};

export const redirectToContact = (id) => {
  redirectTo(() => `/contacts/${id}`);
};

export const redirectToContactEdit = (id) => {
  redirectTo(() => `/contacts/${id}/edit`);
};

export const redirectToContacNew = () => {
  redirectTo(() => '/contacts/new');
};

export const redirectToContacts = () => {
  redirectTo(() => '/contacts');
};

export const redirectToSeasons = () => {
  redirectTo(() => '/seasons');
};

export const redirectToSeasonNew = () => {
  redirectTo(() => '/seasons/new');
};

export const redirectToSeasonEdit = (id) => {
  redirectTo(() => `/seasons/${id}/edit`);
};

export const redirectToCustomFieldsEdit = (formId) => {
  redirectTo(() => `/admin/custom_fields/${formId}/edit`);
};

/**
 * Redirect user to the edit (route) page
 * @param routeId id of route to redirect
 */
export const redirectToRouteEdit = (routeId) => {
  redirectTo(() => `/routes/${routeId}/edit?mode=map`);
};

export const redirectToRoutesIndex = () => {
  redirectTo(() => '/routes');
};

export const redirectToMainPage = () => {
  redirectTo(() => '/sites');
};
export const redirectToUrl = (url) => {
  redirectTo(() => url);
};
