import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useToasts } from '../../../hooks';
import {
  getSiteInfo, updateSite, getMarker,
} from '../../../utils/api/site';
import { styles } from './SiteMap.component.style';
import VamosMap from '../../VamosMap';
import VamosMarker from '../../VamosMap/VamosMarker';
import SiteInfoWindow from '../SitesInMap/SiteInfoWindow';
import DialogModal from '../../DialogModal';

const SiteMap = ({
  site,
  setSiteData,
  icon,
}) => {
  // Hook Toast
  const { showToast } = useToasts();
  const [currentCenter, setCurrentCenter] = useState({
    lat: parseFloat(site.latitude),
    lng: parseFloat(site.longitude),
  });
  const siteMarkerIcon = getMarker({ priority: site.priority, status: site.status, icon });

  const [alertBody, setAlertBody] = useState({
    show: false,
    title: '',
    message: '',
    shouldShowCheckbox: false,
    actions: [],
  });

  const handleCloseUpdateLocationModal = () => {
    setAlertBody({ show: false, shouldShowCheckbox: false });
  };

  const updateAddressNo = () => {
    setCurrentCenter({
      lat: parseFloat(site.latitude),
      lng: parseFloat(site.longitude),
    });
  };

  const updateSiteDetails = () => {
    getSiteInfo(site.id, site.organization_id)
      .then((response) => response.json())
      .then(data => {
        if (!data.site) {
          throw new Error(data.error);
        }
        setSiteData(data.site);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
  };

  const updateAddressYes = async (e, checkedvalue) => {
    await updateSite({
      organization_id: site.organization_id,
      update_address: checkedvalue,
      site: {
        coords_set_manually: true,
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      },
    }, site.id);

    setCurrentCenter({
      lat: parseFloat(e.latLng.lat()),
      lng: parseFloat(e.latLng.lng()),
    });
    updateSiteDetails();
  };

  const handleOpenUpdateLocationModal = (e) => {
    setAlertBody({
      message: 'Do you really want to update the location of the pin?',
      show: true,
      shouldShowCheckbox: true,
      actions: [
        {
          label: 'Yes',
          onClick: (checkedvalue) => { updateAddressYes(e, checkedvalue); handleCloseUpdateLocationModal(); },
        }, {
          label: 'No',
          variant: 'danger',
          onClick: () => { updateAddressNo(); handleCloseUpdateLocationModal(); },
        },
      ],
    });
  };

  return (
    <VamosMap
      className="mb-4"
      center={currentCenter}
      mapStyle={styles.mapStyles}
      zoom={17}
    >
      <VamosMarker
        siteId={site.id}
        lat={currentCenter.lat}
        lng={currentCenter.lng}
        icon={siteMarkerIcon}
        isDraggable
        onDragEnd={handleOpenUpdateLocationModal}
      >
        <SiteInfoWindow
          site={site}
        />
      </VamosMarker>
      <DialogModal
        isVisible={alertBody.show}
        title={alertBody.title}
        message={alertBody.message}
        onClose={handleCloseUpdateLocationModal}
        shouldShowCheckbox={alertBody.shouldShowCheckbox}
        actions={alertBody.actions}
      />
    </VamosMap>
  );
};

SiteMap.defaultProps = {
  icon: undefined,
};

SiteMap.propTypes = {
  site: PropTypes.object.isRequired,
  setSiteData: PropTypes.func.isRequired,
  icon: PropTypes.string || PropTypes.symbol,
};

export default SiteMap;
