/**
 * This function handle response to proccess
 * possible errors with messages from API
 * @param {*} response server response
 * @returns server response parsed
 */
export const handleResponse = async response => {
  const parsedResponse = await response.json().catch(() => {
    // Internal Server Error
    // 'SyntaxError: JSON.parse: unexpected character at line 1 column 1 of the JSON data'
    throw Error('Your request could not be completed at this time. Please contact support team');
  });

  if (!response.ok) {
    // Backend Team is returning a string error property containing a message
    throw Error(parsedResponse.error);
  }
  return parsedResponse;
};
