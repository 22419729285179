import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useDialogs } from '../../../../../hooks';

const SiteFormContactRow = ({
  contact,
  deleteContact,
}) => {
  const { openDialog, closeDialog } = useDialogs();

  const handleDeleteConact = event => {
    event.preventDefault();
    deleteContact(contact.id);
    closeDialog();
  };

  const handleOpenDeleteModal = event => {
    openDialog({
      message: ['Are you sure you want to remove this contact from the site?'],
      show: true,
      actions: [
        {
          label: 'Yes',
          onClick: () => handleDeleteConact(event),
        },
        {
          label: 'No',
          variant: 'danger',
          onClick: closeDialog,
        },
      ],
    });
  };

  return (
    <tr>
      <td>
        <Row>
          <Col lg={9}>
            <p>{contact.name}</p>
          </Col>
          <Col lg={3}>
            <button type="button" className="btn btn-danger" onClick={handleOpenDeleteModal}>Remove Contact From Site</button>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

SiteFormContactRow.propTypes = {
  contact: PropTypes.object.isRequired,
  deleteContact: PropTypes.func.isRequired,
};

export default SiteFormContactRow;
