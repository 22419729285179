export const styles = {
  help: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  h1: {
    fontSize: 40,
    fontWeight: 600,
  },
  h2: {
    fontSize: 32,
    fontWeight: 600,
  },
  h3: {
    fontSize: 20,
    fontWeight: 600,
  },
  h4: {
    fontSize: 14,
    fontWeight: 400,
  },
  h5: {
    paddingTop: '1.5%',
    fontSize: 20,
    fontWeight: 400,
  },
  p: {
    fontSize: 16,
    fontWeight: 400,
  },
  pBox: {
    fontSize: 16,
    fontWeight: 300,
  },
  pWithPadding: {
    fontSize: 16,
    fontWeight: 400,
    paddingTop: '3%',
  },
  div1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '5%',
    height: '48vh',

  },
  div2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    paddingTop: '5%',
    paddingLeft: '16vw',
    height: '70vh',
    backgroundColor: '#F4F3F3',
  },
  resourcesTitle: {
    fontSize: 32,
    fontWeight: 600,
    paddingLeft: '1%',
  },
  resources: {
    display: 'flex',
  },
  resourceBox: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid',
    borderWidth: 'thin',
    width: '21vw',
    minWidth: '18rem',
    minHeight: '15rem',
    height: '38vh',
    marginLeft: '2%',
    padding: '5% 4vw',
    backgroundColor: 'white',
  },
  link: {
    textDecoration: 'underline',
    marginTop: 'auto',
  },
  div3: {
    display: 'flex',
    height: '65vh',
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '55%',
    padding: '0 5%',
    borderRight: 'solid',
    borderWidth: 'thin',
    minWidth: '20rem',
  },
  iframe: {
    background: 'transparent',
    border: '1px solid #ccc',
    width: '56%',
    overflow: 'scroll',
  },
  div4: {
    display: 'flex',
    padding: '0 5%',
    height: '52vh',
    backgroundColor: '#F4F3F3',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '55%',

  },
  img: {
    paddingTop: '8vh',
    marginLeft: 'auto',
    paddingRight: '12%',
  },
  btn: {
    width: 155,
    height: 50,
    backgroundColor: '#40B870',
    color: '#FFFFFF',
    border: 'none',
    marginTop: '5%',
    fontWeight: 600,
  },

};
