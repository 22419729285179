/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { createTag } from '../../../utils/api/tag';
import { redirectToTag } from '../../../utils/navigation';

const TagModal = ({
  organization_id,
  site_ids,
}) => {
  const [name, setName] = useState('');

  const onChange = (e) => {
    setName(e.target.value);
  };

  const onSubmit = () => {
    const formData = {
      organization_id,
      site_ids,
      tag: {
        organization_id,
        name,
      },
    };

    const apiRequest = createTag;
    apiRequest(formData)
      .then((response) => response.json())
      .then((data) => {
        if (!data.tag) {
          throw new Error(data[0]);
        }
        setName('');
        return data.tag.id;
      })
      .then(redirectToTag)
      .catch((error) => {
        // TODO: remove window and add alert component
        // eslint-disable-next-line no-alert
        alert(error);
      });
  };

  return (
    <div>
      <button type="button" className="mt-2 btn btn-outline-primary" data-toggle="modal" data-target="#tagModal">
        Create New Tag From Results
      </button>

      <div className="modal fade" id="tagModal" tabIndex="-1" role="dialog" aria-labelledby="tagModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="tagModalLabel">Create New Tag</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label>Tag Name:
                <input
                  name="tag-name"
                  value={name}
                  onChange={onChange}
                  type="text"
                  className="form-control"
                />
              </label>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" onClick={onSubmit}>Create Tag</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TagModal.propTypes = {
  organization_id: PropTypes.number.isRequired,
  site_ids: PropTypes.array.isRequired,
};

export default TagModal;
