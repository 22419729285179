
export const styles = {
  markerInfoWindowContent: {
    minWidth: '100px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  mapStyles: {
    height: 325,
    width: '100%',
  },
};
