import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { IconButton } from '../FormInputs';
import { getTimeInHoursAndMins } from '../../utils/dates';
import { useRoutes } from '../../hooks';
import { styles } from './Sidebar.style';

const SidebarItem = ({ stop, onDelete }) => {
  // stop.time value is in seconds, we need to convert to minutes to display to user
  const formattedTime = getTimeInHoursAndMins(stop.time);

  // Hook Route Context
  const { vamosMarkersRef } = useRoutes();

  /**
   * This function handle mouse over event
   * in each sidebar item
   */
  const handleOnClick = () => {
    // Get the site marker reference from RoutesContext and execute the onClick function
    if (vamosMarkersRef && vamosMarkersRef.current[stop.site_id]) {
      vamosMarkersRef.current[stop.site_id].props.onClick();
    }
  };

  return (
    <div onClick={handleOnClick} onKeyPress={handleOnClick} role="button" tabIndex={0}>
      <li className="list-group-item">
        <div className="row justify-content-center align-items-center">
          <div style={styles.circle}>
            <small style={styles.textCircle}> {stop.position} </small>
          </div>

          <div className="col-7 d-flex flex-column justify-content-center">
            <small className="text-success font-weight-bold">{stop.site_name}</small>
            <small className="m-0">{stop.address}</small>
          </div>
          <div className="col-3 d-flex flex-column text-right">
            <small style={styles.inlineText} className="m-0">
              {stop.distance
                || Number.isNaN(Number(stop.distance)) || stop.distance === 0
                ? `${stop.distance}`
                : `${stop.distance.toFixed(2)}`}
            </small>
            <small style={styles.inlineText} className="m-0">{formattedTime}</small>
          </div>

          <div className="col-1 d-flex flex-column align-items-center justify-content-center mr-0">
            <IconButton
              icon={<FaTimes color="gray" size={16} />}
              onClick={() => onDelete(stop)}
            />
          </div>
        </div>
      </li>
    </div>
  );
};

SidebarItem.propTypes = {
  stop: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SidebarItem;
