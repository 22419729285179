import React from 'react';
import { PropTypes } from 'prop-types';

const Toast = ({ message, type, onClose }) => {
  return (
    <div className={`single-toast toast-${type}`}>
      <div className="toast-header-container">
        <button
          type="button"
          className="toast-button"
          onClick={onClose}
        >
          x
        </button>
      </div>
      <span className="toast-message">{message}</span>
    </div>
  );
};

Toast.defaultProps = {
  type: 'error',
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  onClose: PropTypes.func.isRequired,
};

export default Toast;
