import React from 'react';
import { PropTypes } from 'prop-types';

const TagNameInput = ({
  name,
  value,
  setValue,
  type,
  className,
}) => {
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <input
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      className={className}
    />
  );
};

TagNameInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
};

TagNameInput.defaultProps = {
  type: 'text',
};

export default TagNameInput;
