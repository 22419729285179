import { ADD_TOAST, REMOVE_TOAST } from '../actionTypes/toasts';

const toastReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_TOAST:
      return [payload, ...state];
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload.id);
    default:
      return state;
  }
};

export default toastReducer;
