export const styles = {
  markerInfoWindowContent: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  flexItem: {
    display: 'inline-block',
    margin: 16,
    fontSize: 15,
  },
  visitDate: {
    marginLeft: 100,
    fontSize: 15,
    padding: 2,
  },
  RoutButton: {
    float: 'right',
    marginLeft: 10,
    color: '#fff',
    backgroundColor: '#018544',
    borderColor: '#018544',
  },
  ViewDetailButtons: {
    color: '#fff',
    backgroundColor: '#018544',
    borderColor: '#018544',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  siteButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  IconStyle: {
    marginLeft: 5,
    color: 'black',
  },
};
