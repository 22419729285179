import React from 'react';

const BackButton = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <button
      className="btn btn-danger"
      type="button"
      onClick={goBack}
    >Back
    </button>
  );
};

export default BackButton;
