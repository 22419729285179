import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { ToggleButton } from './ToggleButton';

/**
 * Component that toggle the visibility of content
 * @returns
 */
const AccordionBody = ({
  className, title, children, eventKey,
}) => {
  return (
    <Accordion className={`card m-1 p-2 ${className}`} defaultActiveKey={eventKey}>
      <ToggleButton label={title} eventKey={eventKey} />
      <Accordion.Collapse eventKey={eventKey}>
        {children}
      </Accordion.Collapse>
    </Accordion>
  );
};

AccordionBody.defaultProps = {
  className: '',
};

AccordionBody.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  // Identifier for each collapse item
  eventKey: PropTypes.string.isRequired,
};

export default AccordionBody;
