import React from 'react';
import { PropTypes } from 'prop-types';
import { capitalizeFirstLetter } from '../../../../../../utils/api/index';

const TrWithLabelNoValue = ({ item, styles }) => {
  const label = capitalizeFirstLetter(item.label);

  return (
    <tr>
      <th className={styles.thClassName.className}>
        <h5>{label}</h5>
      </th>
      <th />
    </tr>
  );
};

TrWithLabelNoValue.propTypes = {
  item: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default TrWithLabelNoValue;
