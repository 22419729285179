/**
 * This component renders a button with transparent background
 * and an icon in the center.
 * @icon needs to be a react-icon component
 */
import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ label, icon, ...props }) => {
  return (
    <button
      type="button"
      className="btn btn-primary-outline btn-sm bg-transparent shadow-none "
      {...props}
    >
      <div className="row align-items-baseline mx-1">
        {label && <h6 className="font-weight-bold text-success">{label}</h6>}
        {icon}
      </div>
    </button>
  );
};

IconButton.defaultProps = {
  label: undefined,
};

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string,
};

export default IconButton;
